import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from '../../util.js';
import axios from 'axios';

import {
  Pane,
  TextInputField,
  Button,
  Heading,
  toaster,
  Paragraph,
  Checkbox,
} from 'evergreen-ui';

function SignupForm(props) {
  const [group, setGroup] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [pw, setPw] = React.useState('');
  const [pwconf, setPwconf] = React.useState('');
  const [checked, setChecked] = React.useState(false);
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(true);

  let navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      navigate('/dashboard');
    } else {
      setLoading(false);
    }
  }, [navigate]);

  const hasContent = field => {
    return field.match("\\S");
  }

  const isSecure = field => {
    return (field.match("[a-zA-Z]") != null && field.match("[0-9]") != null && field.match("[^a-zA-Z0-9]") != null);
  }

  const pwValidationMessage = field => {
    if (!hasContent(field)) {
      return "Password is required";
    } else if (!isSecure(field)) {
      return "Password must include at least one letter, one number, and one special character";
    } else {
      return false;
    }
  }

  const handleSignup = e => {
    e.preventDefault();

    const csrftoken = getCookie('csrftoken');

    const user = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      password1: pw,
      password2: pwconf
    };


    //if we're on production, submit form to hubspot.
    if (process.env.NODE_ENV === 'production') {
      try {
      const portalId = '47511468';
      const formId = 'c633bc85-3aab-4e91-87ce-163db9ac6ebf';
      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

      const payload = {
        fields: [
          {
            name: 'firstname',
            value: firstName
          },
          {
            name: 'lastname',
            value: lastName
          },
          {
            name: 'email',
            value: email
          }
        ],
        // Optional context fields
        context: {
          pageUri: window.location.href,
          pageName: document.title,
        },
      };

      const response = axios.post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('Form submitted successfully:', response.data);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
    }

    fetch('/api/v1/auth/register/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify(user)
    })
      .then(res => res.json())
      .then(data => {
        if (data.non_field_errors) {
          toaster.danger(data.non_field_errors)
        }
        else if (data.password1) {
          toaster.danger(data.password1);
        } else if (data.key) {
          localStorage.clear();
          localStorage.setItem('token', data.key);
          const newtoken = getCookie('csrftoken');
          // create the publisher and pubuser
          fetch('/create_publisher/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': newtoken
            },
            body: JSON.stringify({ publisher: group, token: data.key })
          })
            .then(res => res.json())
            .then(data => {
              localStorage.setItem('userid', data.user);
              localStorage.setItem('publisher', data.publisher);
              localStorage.setItem('publisher_name', group);
              window.location.replace(`/dashboard`);
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          setEmail('');
          setPw('');
          setPwconf('');
          localStorage.clear();
          setErrors(true);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <Pane id="emailSignUp" className={props.emailmode ? "active" : "hidden"}>
      {props.mode !== "invite" && (
        <Pane>
          <Heading marginBottom={16} marginTop={0} size={700} color="var(--darkgreen)">Create a publisher account for free today!</Heading>
          <Heading marginBottom={16} size={400} color="white">
            {/* <a href="https://www.microcosm.pub/workinglit" target="_blank" rel="noreferrer">Learn more about WorkingLit</a> */}
          </Heading>

          <TextInputField
            required
            label="Group Name"
            validationMessage={!hasContent(group) && "Group name is required"}
            onChange={e => setGroup(e.target.value)}
            value={group}
            isInvalid={!hasContent(group)}
          />
        </Pane>
      )}
      <Pane display="flex">
        <TextInputField
          width="100%"
          paddingRight="5%"
          isInvalid={!hasContent(firstName)}
          required
          label="First Name"
          validationMessage={!hasContent(firstName) && "First name is required"}
          onChange={e => setFirstName(e.target.value)}
        />
        <TextInputField
          width="100%"
          paddingLeft="5%"
          isInvalid={!hasContent(lastName)}
          required
          label="Last Name"
          validationMessage={!hasContent(lastName) && "Last name is required"}
          onChange={e => setLastName(e.target.value)}
        />
      </Pane>
      <TextInputField
        required
        label="Email Address"
        validationMessage={!email.match("\\S+@\\S+\\.\\S+") && "Not a valid email address"}
        onChange={e => setEmail(e.target.value)}
        value={email}
        isInvalid={!email.match("\\S+@\\S+\\.\\S+")}
      />
      <TextInputField
        isInvalid={!hasContent(pw) || !isSecure(pw)}
        required
        label="Password"
        description="Must include at least one letter, one number, and one special character"
        validationMessage={pwValidationMessage(pw)}
        onChange={e => setPw(e.target.value)}
        value={pw}
        type="password"
      />
      <TextInputField
        required
        label="Retype Password"
        validationMessage={pwconf !== pw && "Must match password"}
        onChange={e => setPwconf(e.target.value)}
        value={pwconf}
        isInvalid={pwconf !== pw}
        type="password"
        marginBottom={8}
      />
      <Pane display="flex" flexDirection="row" alignItems="center" marginBottom={8}>
        <Checkbox
          label=""
          checked={checked}
          onChange={e => setChecked(e.target.checked)}
        />
        <Paragraph size={300} marginLeft={12}>I agree to the <a href="https://docs.google.com/document/d/1_kjfaoJzEogR4GNToaibl4377smPQVXHaO1OAw4UJbI/edit?usp=sharing" target="_blank" rel="noreferrer">Terms of Service</a>.</Paragraph>

      </Pane>

      <Button
        appearance="primary"
        onClick={e => {
          if (props.mode === "invite") {
            let user = {
              email: email,
              first_name: firstName,
              last_name: lastName,
              password1: pw,
              password2: pwconf
            };
            props.handleSignupFromInvite(e, user)
          } else {
            handleSignup(e);
          }
        }}
        disabled={props.mode === "invite" ? !email || !pw || !pwconf || !checked : !group || !email || !pw || !pwconf || !checked}
      >
        Sign Up!
      </Button>

    </Pane>
  )
}

export default SignupForm;
