import React, { useEffect, useCallback } from "react";
import { useMutation, useQuery } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { setprofile } from '../../redux/user/profileSlice.js';
import EditAccountForm from "./EditAccountForm.js"; // Ensure this is exported correctly
import QUERY_USER from "../../graphql/queries/QueryUser.js";
import Breadcrumbs from "../../components/layout/Breadcrumbs.js";

import {
  Pane,
  Paragraph,
  Card,
  toaster
} from 'evergreen-ui';

import { AddNewForeignKey } from "../../inputs/index.js"; // Ensure this is exported correctly
import { makeAccountInputs } from "../../util.js";
import { setpublisherGlobalData } from "../../redux/currentpublisher/publisherGlobalDataSlice.js";

import CREATE_ORDERSTATUS_MUTATION from "../../graphql/mutations/PublisherData/CreateOrderStatus.js";
import CREATE_SHIPPINGTYPE_MUTATION from "../../graphql/mutations/PublisherData/CreateShippingType.js";
import CREATE_SALESPERSON_MUTATION from "../../graphql/mutations/PublisherData/CreateSalesperson.js";
import CREATE_CUSTOMERCLASS_MUTATION from "../../graphql/mutations/PublisherData/CreateCustomerClass.js";
import CREATE_PAYMENTTERM_MUTATION from "../../graphql/mutations/PublisherData/CreatePaymentTerm.js";
import CREATE_PAYMENTTYPE_MUTATION from "../../graphql/mutations/PublisherData/CreatePaymentType.js";
import CREATE_EXPENSE_CATEGORY_MUTATION from "../../graphql/mutations/PublisherData/CreateExpenseCategory.js";

function UserSettings(props) {
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const profile = useSelector((state) => state.profile.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const dispatch = useDispatch();

  const [createOrderStatus] = useMutation(CREATE_ORDERSTATUS_MUTATION);
  const [createShippingType] = useMutation(CREATE_SHIPPINGTYPE_MUTATION);
  const [createSalesperson] = useMutation(CREATE_SALESPERSON_MUTATION);
  const [createCustomerClass] = useMutation(CREATE_CUSTOMERCLASS_MUTATION);
  const [createPaymentTerm] = useMutation(CREATE_PAYMENTTERM_MUTATION);
  const [createPaymentType] = useMutation(CREATE_PAYMENTTYPE_MUTATION);
  const [createExpenseCategory] = useMutation(CREATE_EXPENSE_CATEGORY_MUTATION);

  const mutations = {
    orderStatus: createOrderStatus,
    shippingType: createShippingType,
    salesperson: createSalesperson,
    customerClass: createCustomerClass,
    paymentTerm: createPaymentTerm,
    paymentType: createPaymentType,
    expenseCategory: createExpenseCategory
  };

  const globalName = {
    orderStatus: "orderstatuss",
    shippingType: "shippingtypes",
    salesperson: "salespersons",
    customerClass: "customerclasses",
    paymentTerm: "paymentterms",
    paymentType: "paymenttypes",
    expenseCategory: "expensecategorys"
  };

  const handleAddNewForeignKey = async (newValue, existingValues, fieldName) => {
    const values = [...existingValues];
    if (existingValues.map(item => item.value).includes(newValue)) {
      toaster.warning("Value already exists!");
    } else {
      try {
        const results = await mutations[fieldName]({
          variables: {
            publisherId: parseInt(currentpublisher.id),
            [fieldName]: newValue,
          }
        });

        const resultName = "create" + fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
        const createdValue = { id: results.data[resultName].message, value: newValue };
        values.push(createdValue);
        dispatch(setpublisherGlobalData({ [globalName[fieldName]]: values }));
      } catch (error) {
        toaster.danger(`Error adding new ${fieldName}: ${error.message}`);
      }
    }
  };

  const inputs = {
    orderStatus: { width: 200, comp: AddNewForeignKey, formLabel: "Order Statuses", group: 1, handleAddNew: handleAddNewForeignKey, values: publisherGlobalData.orderstatuss },
    shippingType: { width: 200, comp: AddNewForeignKey, formLabel: "Shipping Types", group: 1, handleAddNew: handleAddNewForeignKey, values: publisherGlobalData.shippingtypes },
    salesperson: { width: 200, comp: AddNewForeignKey, formLabel: "Sales People", group: 1, handleAddNew: handleAddNewForeignKey, values: publisherGlobalData.salespersons },
    customerClass: { width: 200, comp: AddNewForeignKey, formLabel: "Customer Classes", group: 2, handleAddNew: handleAddNewForeignKey, values: publisherGlobalData.customerclasses },
    paymentTerm: { width: 200, comp: AddNewForeignKey, formLabel: "Payment Terms", group: 2, handleAddNew: handleAddNewForeignKey, values: publisherGlobalData.paymentterms },
    paymentType: { width: 200, comp: AddNewForeignKey, formLabel: "Payment Methods", group: 3, handleAddNew: handleAddNewForeignKey, values: publisherGlobalData.paymenttypes },
    expenseCategory: { width: 200, comp: AddNewForeignKey, formLabel: "Expense Categories", group: 3, handleAddNew: handleAddNewForeignKey, values: publisherGlobalData.expensecategorys },
  };

  const userid = useSelector((state) => state.user.value);
  const { loading, error, data, refetch: _refetch } = useQuery(QUERY_USER, {
    variables: { id: parseInt(userid) },
  });
  const refetch = useCallback(() => { setTimeout(() => _refetch(), 0); }, [_refetch]);

  const handleRefetchUser = () => {
    refetch();
  };

  useEffect(() => {
    if (data && data.hasOwnProperty("user")) {
      dispatch(setprofile(data.user));
    }
  }, [dispatch, data]);

  const formInputs = makeAccountInputs(inputs, props.editing);

  if (loading) return null;
  if (error) return `Error! ${error.message}`;
  console.log(profile);

  return (
    <Pane>
      <Pane borderBottom="solid" borderBottomColor="#e6e4e0" borderBottomWidth="1px" paddingX={16} paddingTop={16}>
        <Breadcrumbs singlePage="HOME" sourceUrl="/dashboard" />
        <Pane marginTop={16} marginBottom={8} size={300} display="flex" justifyContent="space-between">
          <Paragraph marginLeft={4} size={300} fontSize={18}> User Settings </Paragraph>
        </Pane>
      </Pane>
      <Pane padding={16} display="flex" alignItems="flex-start" justifyContent="flex-start" marginRight={16} width="100%" maxHeight="calc(100vh - 83px)" overflow="auto">
        {profile.publisheruserSet && (
          <Card border="default" padding={8} backgroundColor="white" elevation={1}>
            <EditAccountForm publisheruserSet={profile.publisheruserSet} handleRefetchUser={handleRefetchUser} editing={profile.editing} />
          </Card>
        )}
        <Pane display="flex" flexDirection="column">
          <Card border="default" padding={16} marginLeft={16} backgroundColor="white" elevation={1}>
            <Paragraph size={400} fontWeight="bold" color="#5e5e5e">ORDER OPTIONS</Paragraph>
            <Pane display="flex">
              {formInputs.group1}
            </Pane>
          </Card>
          <Pane display="flex">
            <Card border="default" padding={8} margin={16} backgroundColor="white" elevation={1}>
              <Paragraph size={400} fontWeight="bold" color="#5e5e5e">CUSTOMER OPTIONS</Paragraph>
              <Pane display="flex">
                {formInputs.group2}
              </Pane>
            </Card>
            <Card border="default" padding={8} marginY={16} backgroundColor="white" elevation={1}>
              <Paragraph size={400} fontWeight="bold" color="#5e5e5e">ROYALTY/EXPENSE OPTIONS</Paragraph>
              <Pane display="flex">
                {formInputs.group3}
              </Pane>
            </Card>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
}

export default UserSettings;
