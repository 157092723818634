import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { getCookie } from '../../util.js';

// ------- QUERIES
import QUERY_ROYALTY_PAYMENTS from "../../graphql/queries/Royaltys/QueryRoyaltyPayments.js";

// ------- COMPONENTS
import LandingPage from "../../components/layout/LandingPage.js";
import NewRoyaltyPaymentForm from "./NewRoyaltyPaymentForm.js";

import {
  ForeignKeyMultiSelectDropDownInput
} from "../../inputs/index.js";

import {
  Pane,
  AddIcon,
  SideSheet,
} from 'evergreen-ui';
import CloudImage from "../../components/CloudImage.js";

function RoyaltyPayments(props) {


  // ------- STATES
  const location = useLocation();
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);


  const [payments, setPayments] = useState([]);
  const [count, setCount] = useState(0);
  const [pagecount, setPagecount] = useState(0);
  const [currpage, setCurrpage] = useState(1);
  // selection
  const [newmode, setNewmode] = useState(false);
  const [params, setParams] = useState({});
  const [queryTitle, setQueryTitle] = useState(false)




  // ------- HOOKS

  const { loading, error, data, refetch: _refetch } = useQuery(QUERY_ROYALTY_PAYMENTS, {
    variables: { publisher: parseInt(currentpublisher.id), page: parseInt(currpage), params: JSON.stringify(params) },
  });
  const refetch = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch]);

  useEffect(() => {
    if (data) {
      setPayments(data.royaltypayments);
      const csrftoken = getCookie('csrftoken');
      fetch('/count_royalty_payments/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken
        },
        body: JSON.stringify({ publisher: currentpublisher.id })
      })
        .then(res => res.json())
        .then(data => {
          setCount(parseInt(data.message));
          setPagecount(Math.ceil(data.message / 25));
        })
    }
  }, [currentpublisher.id, data]);

  useEffect(() => {
    if (location.state) {
      let locState = location.state;
      setQueryTitle(locState.queryTitle)
    }
  }, [location.state]);


  // ------- FUNCTIONS
  const handleRefetchPayments = () => {
    setParams({});
    refetch();
  }

  const handleToggleNewMode = () => {
    setNewmode(!newmode);
  }

  // ------- INPUTS

  const buttons = [
    { title: "NEW PAYMENT", icon: AddIcon, onClick: setNewmode },
  ];

  const cols = [
    { title: "DATE", type: "button", flex: "2", query: "sent_date" },
    { title: "AMOUNT", type: "button", flex: "2", query: "amount", align: "right" },
    { title: "CREATOR", type: "button", flex: "4", query: "royalty__creator__display_name" },
    { title: "ROYALTY", type: "button", flex: "4", query: "royalty__product__title" },
    { title: "PRODUCT ISBN", type: "button", flex: "3", query: "royalty__product__isbn" },
    { title: "METHOD", type: "button", flex: "3", query: "method__value" },
  ];

  const searchInit = {
    royalty__id: "",
    royalty__product__title: "",
    royalty__creator__display_name: "",
    method__value: ""

  }

  const searchInputs = {
    default: { searchq: "royalty__product__title", dropLabel: "" },
    royalty__product__title: { searchq: "royalty__product__title", dropLabel: "Product Title" },
    royalty__creator__display_name: { searchq: "royalty__creator__display_name", dropLabel: "Creator" },
    royalty__id: { searchq: "royalty__id", dropLabel: "Royalty ID" },

  }

  const filterInputs = {
    method__value: { comp: ForeignKeyMultiSelectDropDownInput, formLabel: "METHODS", group: 1, values: publisherGlobalData.paymenttypes, marginRight: 4 },
  }


  const csvFields = [
    "amount",
    "created",
    "creator",
    "id",
    "product",
    "paymentType",
    "amount",
    "sentDate"
  ];

  const csvConfig = {
    "creator": ["paymentName", "displayName"],
    "product": ["title", "isbn", "id"],
    "paymentType": ["value"],
    "sets": {
      "royalty": {
        "fields": ["creator", "product"],
        "subfields": {
          // "creator": 
        },
        "label": "products"
      }
    },
    "primarySets": {},
    "arrays": {},
  }

  if (error) return `Error! ${error}`;

  return (
    <Pane position="absolute" padding={16} width="100%" height="100%">
      <SideSheet isShown={newmode} onCloseComplete={() => setNewmode(false)} className="background">
        <NewRoyaltyPaymentForm
          handleToggleNewMode={handleToggleNewMode}
          handleRefetchPayments={handleRefetchPayments}
          publisher={currentpublisher.id}
          fromStatement={false}
        />
      </SideSheet>
      <LandingPage
        title="Royalty Payments"
        location={location}
        queryTitle={queryTitle}
        data={payments}
        loading={loading}
        buttons={buttons}
        count={count}
        pagecount={pagecount}
        searchInputs={searchInputs}
        filterInputs={filterInputs}
        searchInit={searchInit}
        csvConfig={csvConfig}
        csvFields={csvFields}
        csvType="RoyaltyPayments"
        cols={cols}
        refetch={refetch}
        setParams={setParams}
        RowComponent={RoyaltyPaymentRow}
        currpage={currpage}
        setCurrpage={setCurrpage}
      />
    </Pane>
  )
}

function RoyaltyPaymentRow({ item, allmode, handleCheck, colWidths, makeIDCell, makeTableCellArray }) {
  const [checked, setChecked] = useState(false);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);

  const handleSetChecked = () => {
    handleCheck(checked, item)
    setChecked(!checked);
  }
  const royalty = item.royalty
  const currency = publisherGlobalData.currency
    ? publisherGlobalData.currency.symbol
    : "$";

  const rowCells = [
    {
      content: makeIDCell(checked, handleSetChecked, item.id), disableSelect: true
    },
    { content: item.sentDate, disableSelect: true },
    { isNumber: true, content: <Pane>{currency}{item.amount.toFixed(2)}</Pane>, disableSelect: true },
    {
      content:
        <Pane display="flex" alignItems="center"> <CloudImage path={royalty.creator.photoUrl ? royalty.creator.photoUrl : ""} avatar={true} size={30} name={royalty.creator.displayName} />
          <Pane marginLeft={8}>
            <Link to={`/creators/${royalty.creator.id}`}>{royalty.creator.displayName}</Link>
          </Pane>
        </Pane>,
      disableSelect: true
    },
    {
      content: <Link to={`/royalties/${royalty.id}`}> #{royalty.id}: {royalty.product.title} ({royalty.product.format ? royalty.product.format.description : "No Format"})</Link>
      , disableSelect: true
    },
    { content: royalty.product.isbn, disableSelect: true },
    { content: item.method ? item.method.value : "(No payment method)", disableSelect: true },
  ]

  useEffect(() => {
    setChecked(allmode);
  }, [allmode]);
  const cellArray = makeTableCellArray(rowCells, colWidths, item.id)

  return cellArray
}

export default RoyaltyPayments;