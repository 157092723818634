import { createSlice } from '@reduxjs/toolkit';

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    value: {
      id: false,
      firstName: false,
      lastName: false,
      email: false,
      editing: false,
    },
  },
  reducers: {
    setprofile: (state, action) => {
      // Create a shallow copy of action.payload
      const payloadCopy = { ...action.payload };

      // Check if __typename exists and delete it if it does
      if (payloadCopy.hasOwnProperty("__typename")) {
        delete payloadCopy.__typename;
      }

      // Merge the current state with the new payload
      state.value = { ...state.value, ...payloadCopy };
    },
  },
});

export const { setprofile } = profileSlice.actions;

export default profileSlice.reducer;
