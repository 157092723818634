import React, { useEffect, useState, useRef } from "react";
import {
  SelectMenu,
  Button,
} from 'evergreen-ui';

function SelectContactInfo(props) {
  const [selected, setSelected] = useState("");
  const [label, setLabel] = useState('Choose...');
  const prevStagedRef = useRef();
  
  const keys = {
    "email": "orderEmail",
    "phone": "orderPhone",
    "address": "orderAddress"
  };

  const handleSetChecked = item => {
    console.log("item Type=====", keys[props.itemType])
    props.handleChooseInfo(item, keys[props.itemType]);
  };

  const mode = props.itemType === "email" || props.itemType === "phone" ? "simple" : "complex";
  
  useEffect(() => {
    // Prevent unnecessary updates
    if (props.staged === prevStagedRef.current) {
      return;
    }
    prevStagedRef.current = props.staged;

    // Add null checks for props.staged
    if (props.staged && props.itemSet?.length > 0) {
      const stagedId = props.staged.id;
      if (stagedId && props.itemSet.some(({id}) => id === stagedId)) {
        const firstItem = props.staged;
        const primaryLabel = firstItem.primary === "" ? 
          (props.itemType === "email" ? 'Change Email...' : 
          props.itemType === "phone" ? 'Change Phone...' : 'Change Address') :
          (mode === "simple" ? firstItem.value : 
          ["line1", "line2", "city", "stateOrRegion", "zipcode", "country"]
            .map(field => firstItem[field])
            .filter(Boolean)
            .join(", "));
  
        console.log("JSON.Stringify staged =>>> ", props)
          
        setSelected(JSON.stringify(props.staged));
        // Only call handleSetChecked if the value has actually changed
        if (JSON.stringify(props.staged) !== selected) {
          handleSetChecked(JSON.stringify(props.staged));
        }
        setLabel(primaryLabel);
      } else {
        // Default to first item if staged item not found
        const firstItem = props.itemSet[0];
        const primaryLabel = firstItem.primary === "" ? 
          (props.itemType === "email" ? 'Change Email...' : 
          props.itemType === "phone" ? 'Change Phone...' : 'Change Address') :
          (mode === "simple" ? firstItem.value : 
          ["line1", "line2", "city", "stateOrRegion", "zipcode", "country"]
            .map(field => firstItem[field])
            .filter(Boolean)
            .join(", "));
        
        const newSelected = JSON.stringify(firstItem);
        setSelected(newSelected);
        // Only call handleSetChecked if the value has actually changed
        if (newSelected !== selected) {
          handleSetChecked(newSelected);
        }
        setLabel(primaryLabel);
      }
    } else if (props.itemSet?.length > 0) {
      const firstItem = props.itemSet[0];
      const primaryLabel = firstItem.primary === "" ? 
        (props.itemType === "email" ? 'Change Email...' : 
        props.itemType === "phone" ? 'Change Phone...' : 'Change Address') :
        (mode === "simple" ? firstItem.value : 
        ["line1", "line2", "city", "stateOrRegion", "zipcode", "country"]
          .map(field => firstItem[field])
          .filter(Boolean)
          .join(", "));
  
      const newSelected = JSON.stringify(props.itemSet[0]);
      setSelected(newSelected);
      // Only call handleSetChecked if the value has actually changed
      if (newSelected !== selected) {
        handleSetChecked(newSelected);
      }
      setLabel(primaryLabel);
    } else {
      setLabel('No Data...');
      setSelected("");
    }
  }, [props.staged, props.itemSet, props.itemType, mode, selected]);

  useEffect(()=>{
    console.log("Saged Object", props.staged)
  }, [props.staged])

  return (
    <SelectMenu
      hasTitle={false}
      options={props.itemSet?.map(item => ({
        key: `${props.itemType}${item.id}`,
        value: JSON.stringify(item),
        label: mode === "simple" ? item.value : 
          ["line1", "line2", "city", "stateOrRegion", "zipcode", "country"]
            .map(field => item[field])
            .filter(Boolean)
            .join(", "),
      }))}
      selected={selected}
      closeOnSelect={true}
      onSelect={item => {
        handleSetChecked(item.value);
        setSelected(item.value);
        setLabel(item.label);
      }}
      width={300}
    >
      <Button size="small">{label}</Button>
    </SelectMenu>
  );
}

export default SelectContactInfo;
