import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useDropzone } from "react-dropzone";
import AddCustomer from "../../views/Orders/AddCustomer.js";
import AddContactInfo from "../../inputs/select/SelectContactInfo.js";
import { baseOrderObject } from "../../views/Orders/BaseOrderObject.js";

import JSON5 from 'json5';

import { getCookie, csvToArray, arrayToCSV } from '../../util.js';

import {
  Pane,
  Paragraph,
  Button,
  Table,
  Text,
  toaster,
  Card,
  Tooltip,
  Badge,
  PaperclipIcon,
  InfoSignIcon,
  Spinner,
  ErrorIcon,
} from 'evergreen-ui';

function CsvUpload(props) {
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const [dropped, setDropped] = useState(false);
  const [finished, setFinished] = useState(false);
  const [importError, setImportError] = useState(false);
  const [valid, setValid] = useState(false);
  const [preview, setPreview] = useState(false);
  const [results, setResults] = useState([]);
  const [staged, setStaged] = useState(baseOrderObject);

  const handleChooseInfo = (itemString, itemType) => {
    let item = JSON.parse(itemString)
    handleUpdateStaged({ key: itemType, val: item });
  }

  const handleUpdateStaged = obj => {
    if (obj.key === "customer") {
      setStaged({ ...baseOrderObject, [obj.key]: obj.val })

    } else {
      setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }))
    }

  }

  const hasCustomer = staged.customer && staged.customer.id
    ? true
    : false;

  const hasEmail = staged.orderEmail && staged.orderEmail.id
    ? true
    : false;

  const hasPhone = staged.orderPhone && staged.orderPhone.id
    ? true
    : false;

  const hasAddress = staged.orderAddress && staged.orderAddress.id
    ? true
    : false;

  const importItems = async () => {
    const csrftoken = getCookie('csrftoken');
    toaster.notify("Importing records", { duration: 30 });

    let inputData = {
      type: props.itemType,
      contents: results,
      pubId: currentpublisher.id
    };

    if (props.itemType === "order") {
      let customerInfo = {
        customer: staged.customer.id,
        phone: staged.orderPhone ? staged.orderPhone.id : "",
        address: staged.orderAddress ? staged.orderAddress.id : "",
        email: staged.orderEmail ? staged.orderEmail.id : ""
      }
      inputData = { ...inputData, customer: customerInfo }
    }

    try {
      const response = await fetch('/import_csv/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken
        },
        body: JSON.stringify(inputData)
      })

      const data = await response.json()
      if (data.message === "Items imported") {
        toaster.closeAll();
        toaster.success("Finished importing records!", { duration: 10 });
        setDropped(false)
        setFinished(false)
        setValid(false)
        setResults(false)
        setPreview(false)
        props.handleToggleCsvMode();


      } else {
        toaster.closeAll();
        toaster.warning(data.message, { duration: 60 });
      }


    } catch (error) {
      toaster.closeAll()
      toaster.danger("Error importing records. Try again or contact developer", { duration: 15 })
      console.log(error.message);
    }




  }

  const fileDrop = async (file, otherInputs) => {
    // read the csv file
    // for each row, create a new record
    // also allow download of the csv template

    const csrftoken = getCookie('csrftoken');
    // toaster.notify("Importing records", { duration: 60 });

    var reader = new FileReader();
    reader.onload = function (e) {
      var contents = e.target.result;
      setResults(contents)
      setPreview(true)


      let inputData = {
        type: props.itemType,
        contents: contents,
        pubId: currentpublisher.id,

      }

      if (props.itemType === "order") {
        let customerInfo = {
          customer: otherInputs.customer.id,
          phone: otherInputs.orderPhone ? otherInputs.orderPhone.id : "",
          address: otherInputs.orderAddress ? otherInputs.orderAddress.id : "",
          email: otherInputs.orderEmail ? otherInputs.orderEmail.id : ""
        }
        inputData = { ...inputData, customer: customerInfo }
      }

      fetch('/validate_csv/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken
        },
        body: JSON.stringify(inputData)
      })
        .then(res => res.json())
        .then(data => {
          setFinished(true);
          let obj = JSON5.parse(data.message);
          setValid(obj)
        })
        .catch((error) => {
          console.log(error);
          setPreview(false)
          setFinished(false)
          setValid(false)
          setImportError("File formatted improperly - check for extra commas!")
        });
    }
    reader.readAsText(file);

  }

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    acceptedFiles.forEach(file => {
      let name = file.name.toLowerCase();
      if (
        name.endsWith(".csv")
      ) {
        setResults([])
        setPreview(false)
        setFinished(false);
        setImportError(false)
        setDropped(file.name);
        fileDrop(file, staged);
      } else {
        toaster.warning("You can only upload .csv files");
      }
    });
  }, [staged]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const downloadFile = () => {
    const csvString = arrayToCSV(props.template)
    const fileToDownload = new Blob([csvString], { type: 'text/plain' });
    const element = document.createElement("a");
    element.href = URL.createObjectURL(fileToDownload);
    element.download = props.itemType + "-template.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  useEffect(() => {

  }, [dropped])


  const errorCount = valid ? Object.keys(valid).map(row => Object.keys(valid[row].errors).length).reduce((total, current) => { return total + current; }, 0) : false
  const warningCount = valid ? Object.keys(valid).map(row => Object.keys(valid[row].warnings).length).reduce((total, current) => { return total + current; }, 0) : 0


  return (
    <Pane >
      <Card paddingBottom={16} backgroundColor="white" height="100%" elevation={2}>
        <Pane display="flex" justifyContent="space-between" alignItems="center" borderBottom="solid" borderBottomColor="var(--lightgrey)" borderBottomWidth={1} paddingY={8}>
          <Paragraph size={400} fontWeight="bold" color="#5e5e5e" marginLeft={16}>Import {props.title}</Paragraph>

          <Pane>
            <Button marginRight={16} className="confirm-button" size="small" disabled={errorCount !== 0} onClick={e => {
              importItems();
            }}>
              IMPORT
            </Button>
            <Button size="small" marginRight={16} onClick={e => {
              props.handleToggleCsvMode();
            }}>
              Cancel
            </Button>


          </Pane>

        </Pane>
        <Pane display="flex" marginTop={16} marginX={16} justifyContent="center" >
          <Pane flex={1} display="flex" flexDirection="column">

            <Pane display="flex" alignItems="center" marginBottom={4}>
              <Paragraph size={300} fontWeight="bold" color="#5e5e5e" >FILE UPLOADER</Paragraph>
              <Tooltip content={<Pane >
                <Paragraph size={300} color="white">
                  To import multiple {props.itemType}s into WorkingLit, upload a
                  an appropriately formatted .csv file - download the template below for details.
                </Paragraph>
                <Paragraph color="red" size={300} marginTop={8}>
                  Note: Additional commas in your csv content could cause the import to
                  be corrupted.
                </Paragraph></Pane>}>
                <InfoSignIcon marginLeft={6} size={12} color="var(--robinegg)" />
              </Tooltip>
              {dropped && !finished && !importError && (
                <Pane display="flex" marginLeft={8}>
                  <Paragraph>Importing: {dropped}</Paragraph>
                  <Spinner marginLeft={8} marginTop={4} size={16} />
                </Pane>
              )}
              {dropped && !finished && importError && (
                <Paragraph marginLeft={8}>Error while importing {dropped}: {importError}</Paragraph>
              )}
            </Pane>
            {props.itemType === "order" && !hasCustomer ?
              <Pane display="flex" backgroundColor="var(--lightgrey)" justifyContent="center" alignItems="center" flex={1} maxHeight={174}>

                <ErrorIcon size={20} color={"var(--robinegg)"} />
                <Pane display="flex" marginLeft={8}>
                  <Paragraph color="var(--robinegg)" >Choose customer before uploading CSV</Paragraph>
                </Pane>
              </Pane>
              :
              <Pane {...getRootProps()} className="file-uploader" display="flex" justifyContent="center" alignItems="center" flex={1} maxHeight={174}>
                <input {...getInputProps()} />
                <PaperclipIcon size={20} color={"var(--robinegg)"} />
                <Pane display="flex" marginLeft={8}>
                  {isDragActive ? <Paragraph color="var(--robinegg)" >Drop file here</Paragraph> :
                    <Pane display="flex">
                      <Paragraph color="var(--green)" textDecoration="underline" cursor="pointer">Choose {finished && "new"} file</Paragraph>
                      <Paragraph color="var(--robinegg)" marginLeft={4}> or drag and drop file here</Paragraph>
                    </Pane>
                  }

                </Pane>
              </Pane>
            }


          </Pane>
          {props.itemType === "order" &&
            <Pane id="customer-info" marginX={16} paddingLeft={16} width="50%">

              <Paragraph size={300} fontWeight="bold" color="#5e5e5e" >CUSTOMER INFO</Paragraph>
              <Pane display="flex" marginTop={8} >
                <Pane >
                  <Pane>
                    <Paragraph color="#667b7f" size={300}>Customer </Paragraph>
                    <AddCustomer mode={hasCustomer ? "Change" : "Choose"} handleChooseInfo={handleChooseInfo} />
                  </Pane>

                  <Pane marginTop={16}>
                    <Paragraph color="#667b7f" size={300}>Email Address </Paragraph>
                    {hasCustomer ? (
                      <AddContactInfo
                        mode={hasEmail ? "Change" : "Choose"}
                        handleChooseInfo={handleChooseInfo}
                        itemSet={staged.customer.customeremailSet}
                        itemType="email"
                      />
                    ) : (
                      <Paragraph size={300} marginTop={4} color="grey" marginBottom={16}>(CHOOSE CUSTOMER FIRST)</Paragraph>
                    )}

                  </Pane>

                </Pane>

                <Pane marginX={32}>
                  <Pane >

                    <Paragraph color="#667b7f" size={300}>Phone Number </Paragraph>

                    {hasCustomer ? (
                      <AddContactInfo
                        mode={hasPhone ? "Change" : "Choose"}
                        handleChooseInfo={handleChooseInfo}
                        itemSet={staged.customer.customerphonenumberSet}
                        itemType="phone"
                      />
                    ) : (
                      <Paragraph size={300} marginTop={4} color="grey" marginBottom={16}>(CHOOSE CUSTOMER FIRST)</Paragraph>
                    )}
                  </Pane>


                  <Pane marginTop={24}>
                    <Paragraph color="#667b7f" size={300}>Shipping Address </Paragraph>

                    {hasCustomer ? (
                      <AddContactInfo
                        mode={hasAddress ? "Change" : "Choose"}
                        handleChooseInfo={handleChooseInfo}
                        itemSet={staged.customer.customeraddressSet}
                        itemType="address"
                      />
                    ) : (
                      <Paragraph size={300} marginTop={4} color="grey" marginBottom={16}>(CHOOSE CUSTOMER FIRST)</Paragraph>
                    )}

                  </Pane>

                </Pane>
                {/* {hasAddress && (
                  <AddressItem address={staged.orderAddress} editing={false} />
                )} */}

              </Pane>


            </Pane>}
          {/* {dropped && finished && (
            <Pane marginLeft={16} flex="2" overflowX="scroll">
              <Pane display="flex" alignItems="center" marginBottom={8} >
                <Paragraph size={300} fontWeight="bold" color="#5e5e5e">IMPORT STATUS</Paragraph>
                <Badge marginLeft={8} color={errorCount ? "red" : "green"}>{errorCount} Errors</Badge>
                <Badge marginLeft={8} color={warningCount ? "yellow" : "green"}>{warningCount} Warnings</Badge>
              </Pane>
              <Table.Head className="error-table" height={16}>
                <Table.TextCell flex={1} textAlign="center"><Paragraph size={300} fontWeight="bold" >ROW #</Paragraph></Table.TextCell>
                <Table.TextCell flex={3}>ERRORS</Table.TextCell>
                <Table.TextCell flex={3}>WARNINGS</Table.TextCell>

              </Table.Head>
              <Pane maxHeight={150} overflowY="scroll" borderBottom="default" borderLeft="default" borderRight="default">
                {Object.keys(valid).map(row => {
                  if (Object.keys(valid[row].errors).length || Object.keys(valid[row].warnings).length)
                    return (
                      <Table.Row className="table-row">
                        <Table.TextCell textAlign="center"><Paragraph size={300} fontWeight="bold">{row}</Paragraph></Table.TextCell>
                        <Table.TextCell flex={3}>
                          {Object.keys(valid[row].errors).map(col => { return <Paragraph size={300} marginBottom={4}>{col}: {valid[row].errors[col]}</Paragraph> })}
                        </Table.TextCell>
                        <Table.TextCell flex={3}>
                          {Object.keys(valid[row].warnings).map(col => { return <Paragraph size={300} marginBottom={4}>{col}: {valid[row].warnings[col]}</Paragraph> })}
                        </Table.TextCell>
                      </Table.Row>)
                }
                )}

              </Pane>

            </Pane>
          )} */}
        </Pane>

        <Pane marginTop={16} marginX={16} backgroundColor="white" >
          {!finished && !preview && (
            <Pane>
              <Pane display="flex" alignItems="center">
                <Paragraph size={300} fontWeight="bold" color="#5e5e5e">EXAMPLE FILE</Paragraph>
                <Button marginLeft={16} size="small" onClick={e => {
                  downloadFile();
                }}>
                  Download Template
                </Button></Pane>
              <Pane marginTop={8} className="csvTable">
                <CsvExample csvTemplate={props.template} tableWidth={props.tableWidth} colWidths={props.colWidths} />
              </Pane>
            </Pane>

          )}
          {preview && (
            <Pane>
              <Pane display="flex">
                <Paragraph size={300} fontWeight="bold" color="#5e5e5e">IMPORT PREVIEW</Paragraph>
                <Paragraph size={300} marginLeft={8} >{dropped}</Paragraph>
                {results.length && <Badge marginLeft={8} color={"neutral"}>{csvToArray(results).length} Rows</Badge>}
                <Badge marginLeft={8} color={errorCount ? "red" : "green"}>{errorCount} Errors</Badge>
                <Badge marginLeft={8} color={warningCount ? "yellow" : "green"}>{warningCount} Warnings</Badge>

              </Pane>
              <Pane marginTop={8} className="csvTable">
                <CsvPreview csvPreview={results} template={props.template} validation={valid} tableWidth={props.tableWidth} colWidths={props.colWidths} />
              </Pane>
            </Pane>
          )}
        </Pane>
      </Card>
    </Pane>
  )
}

const CsvExample = (props) => {

  return (
    <Pane width={props.tableWidth} elevation={1}>
      <Pane overflow="auto" borderBottom="default">
        <Table.Head className="table-heading">
          {props.csvTemplate[0].map((item, index) => {

            return (
              <Table.TextCell key={item} flex={props.colWidths[index]}
              ><Text className="breakable" size={300} >{item}</Text></Table.TextCell>
            )
          })}
        </Table.Head>
        <Table.Row className="table-row csvDescription">
          {props.csvTemplate[1].map((item, index) => {

            return (
              <Table.TextCell key={`desc${index}`} flex={props.colWidths[index]}><Text className="breakable" size={300} >{item}</Text></Table.TextCell>

            )
          })}
        </Table.Row>

        {props.csvTemplate.slice(2).map((row, rowIndex) =>
          <Table.Row className="table-row">
            {row.map((item, index) =>
              <Table.TextCell key={`${rowIndex}ex${index}`} flex={props.colWidths[index]}><Text className="breakable" size={300}>{item}</Text></Table.TextCell>
            )}
          </Table.Row>
        )}
      </Pane>

    </Pane>

  )
}

const CsvPreview = (props) => {

  if (props.csvPreview.length > 0) {
    const previewData = csvToArray(props.csvPreview)
    return (
      <Pane width={props.tableWidth} >
        <Pane>
          <Table.Head key="previewHeader" className="table-heading">
            <Table.TextCell textAlign="center" padding={4} flex={2}>
              CSV Row
            </Table.TextCell>
            {previewData[0].map((item, index) => {
              return (
                <Table.TextCell key={`preview${item}`} flex={props.colWidths[index]} >{item}</Table.TextCell>
              )
            })}
          </Table.Head>
          <Pane maxHeight={500} overflowY="scroll">
            {previewData.slice(1).map((row, rowIndex) => {
              if (row !== props.template[1]) {
                let errors = props.validation[rowIndex] ? props.validation[rowIndex].errors : {} 
                let warnings = props.validation[rowIndex] ? props.validation[rowIndex].warnings : {}
                let rowColor = props.validation[rowIndex] ? props.validation[rowIndex].duplicate.length ? "#FFEFD2" : "white" : "white"
                let reference = props.validation[rowIndex] ? props.validation[rowIndex].reference ? true : false : false
                return (
                  <Table.Row 
                    key={`row-${rowIndex}`}
                    className="table-row" 
                    borderBottom={reference ? "dashed" : "none"} 
                    border={reference ? "dashed" : "none"} 
                    borderColor="var(--robinegg)"
                  >
                    <Table.TextCell textAlign="center" padding={4} backgroundColor={rowColor} flex={2}>
                      {(rowColor !== "white" || reference) && <Tooltip content={
                        <Pane>
                          {reference &&
                            <Paragraph size={300} color="white">
                              Reference row for non-product fields
                            </Paragraph>}
                          {props.validation[rowIndex].duplicate.includes("app") &&
                            <Paragraph size={300} color="white">
                              Potential duplicate found in WorkingLit
                            </Paragraph>}
                          {props.validation[rowIndex].duplicate.includes("csv") &&
                            <Paragraph size={300} color="white">
                              Potential duplicate found in this CSV
                            </Paragraph>}
                        </Pane>
                      }>
                        <InfoSignIcon color="muted" size={12} marginLeft={4} />
                      </Tooltip>}
                      <Paragraph size={300}> {rowIndex + 2}
                      </Paragraph>

                    </Table.TextCell>
                    {row.map((item, colIndex) => {
                      let colName = previewData[0][colIndex]
                      let cellColor = colName in errors ? "#F9DADA" : colName in warnings ? "#FFEFD2" : "white"
                      return (
                        <Table.TextCell 
                          key={`${rowIndex}-${colIndex}`}
                          backgroundColor={cellColor} 
                          flex={props.colWidths[colIndex]} 
                          textAlign="center" 
                          padding={4}
                        >
                          {(colName in errors || colName in warnings) &&
                            <Tooltip content={
                              <Paragraph size={300} color="white">
                                {errors[colName]} {warnings[colName]}
                              </Paragraph>
                            }>
                              <InfoSignIcon color="muted" size={12} marginLeft={4} />
                            </Tooltip>
                          }
                          <Pane>
                            <Text className="breakable" size={300} >{item}</Text>
                          </Pane>

                        </Table.TextCell>
                      )
                    })}
                  </Table.Row>
                )

              } else {
                return <Pane></Pane>
              }

            })}
          </Pane>
        </Pane>

      </Pane>
    )
  }
  else {
    return (
      <Pane>{props.csvPreview}</Pane>

    )
  }


}

export default CsvUpload;