// TODO: Rename, redo according to Elly notes
import React, { useState, useRef } from "react";
import { makeInputs } from '../../util.js';

import {
  Pane,
  Button,
  SelectMenu,
  SearchInput,
  CaretDownIcon,
  Card
} from 'evergreen-ui';

function SearchAdvanced(
  { searches, filters, staged, handleSearch, handleResetSearch, setFilterDetails, filterDetails
  }) {
  const [stagedParams, setStagedParams] = useState({exclude: {}})
  const inputRef = useRef(null);

  const selectOptions = Object.values(searches)

  const anyStaged = selectOptions.some(k => staged[k.searchq] !== "");
  const stagedSearch = anyStaged ? selectOptions.filter(k => staged[k.searchq] !== "") : selectOptions[0]

  const [searchInput, setSearchInput] = useState(staged[stagedSearch.searchq]);
  const [searchButton, setSearchButton] = useState(stagedSearch.dropLabel);
  const [searchKey, setSearchKey] = useState(stagedSearch.searchq);
  const [advancedOption, setAdvancedOption] = useState(false)

  const handleChangeSearchParams = obj => {
    let params = stagedParams
    let value;
    if (obj.key === 'shipping_type__value' || obj.key === 'payment_term__value') {
      value = obj.val.value;
    } else {
      value = obj.val;
    }
    // if (obj.key.match(/created_/)) {
    //   value = obj.val
    // }

    if (obj.hasOwnProperty('exclude')) {
      let excluded = params.exclude
      params.exclude = {...excluded, [obj.key]: obj.exclude}
    }
   
    if (obj.val == null || obj.val === "" || obj.val.length === 0) {
      delete params[obj.key]
    } else {
      params = { ...params, [obj.key]: value };
    }

    setStagedParams({ ...params })
  }

  let filterKeys = Object.keys(filters);
  filterKeys.forEach(key => {
    filters[key].handleChange = handleChangeSearchParams;
    filters[key].setDetails = setFilterDetails;
    filters[key].details = filterDetails;
  }
  );

  const formInputs = makeInputs(staged, filters, true);

  const handleUpdateStaged = () => {
    // Create new objects instead of modifying existing ones
    let params = { ...stagedParams };
    let stagedSearch = { ...staged };

    // First add all staged params
    for (let [key, value] of Object.entries(params)) {
      stagedSearch[key] = value;
    }

    // Then handle the search fields
    selectOptions.forEach(option => {
      let field = option.searchq;
      if ((field !== searchKey && stagedSearch.hasOwnProperty(field)) || searchInput === "") {
        delete params[field];
        stagedSearch[field] = "";
      } else if (field === searchKey && searchInput !== "") {
        params[searchKey] = searchInput;
        stagedSearch[searchKey] = searchInput;
      }
    });
    return [params, stagedSearch]
  }

  const handleConfirmSearch = () => {
    let [params, searchStaged] = handleUpdateStaged()
    console.log("PARAMS", searchStaged);
    handleSearch( params, searchStaged)
  }

  const handleOnchange = () => {
    if (inputRef.current.value === '') {
      console.log('Clear icon clicked');
      let [params, searchStaged] = handleUpdateStaged();
      setFilterDetails({})
      setStagedParams({})
      setSearchInput("")
      handleResetSearch(searchStaged)
    } else {
      setSearchInput(inputRef.current.value);
      handleConfirmSearch();
    }
  }

  return (
    <Card width="100%" display="flex" padding={8} borderRadius={10} border="default" justifyContent="space-between"
      backgroundColor="white" elevation={1} alignItems="flex-end">

      <Pane display="flex" justifyContent="center" alignItems="flex-end" >
        <SearchInput
          className={"drop-search"}
          ref={inputRef}
          placeholder={"Search..."}
          value={searchInput}
          onChange={handleOnchange}
          onKeyPress={e => { if (e.key === 'Enter') handleConfirmSearch(); }}
          autoFocus
        />
        <SelectMenu
          hasTitle={false}
          hasFilter={false}
          options={selectOptions.map(
            item => ({
              key: item.dropLabel,
              value: item.searchq,
              label: item.dropLabel
            })
          )}
          selected={searchButton}
          closeOnSelect={true}
          onSelect={(option) => {
            setSearchButton(option.label)
            setSearchKey(option.value)
          }}
        >
          <Button className="search-dropdown" iconAfter={CaretDownIcon}>{searchButton} </Button>
        </SelectMenu>

        <Button marginX={8} className="confirm-button" onClick={e => {
          handleConfirmSearch()
        }}>SEARCH</Button>

        {
          advancedOption && <>
            <Pane display="flex" marginLeft={16} alignItems="flex-end">
              {formInputs.group1}
            </Pane>
          </>
        }
        <Pane display="flex" marginLeft={10}>
          <Button
              onClick={() => {
                if(!advancedOption) {
                  setAdvancedOption(true)
                } else {
                  setAdvancedOption(false)
                }
              }}
            >
              { advancedOption ? 'Cancel' : 'Advanced' }
            </Button>
          {/* <Button marginRight={8} onClick={e => {
            let [params, searchStaged] = handleUpdateStaged()
            setFilterDetails({})
            setStagedParams({})
            setSearchInput("")
            handleResetSearch(searchStaged)
          }}
          >Reset</Button> */}
          {/* <IconButton icon={DoubleChevronDownIcon} onClick={e => SetShowFilters(!showFilters)}> */}
          {/* </IconButton> */}


        </Pane>
      </Pane>

    </Card>
  );
}

export default SearchAdvanced;
