import React, { useState, useEffect, useCallback } from "react";
import { useLocation, } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { getCookie} from '../../util.js';

// ------- QUERIES
import QUERY_ROYALTYS from "../../graphql/queries/Royaltys/QueryRoyaltys.js";

// ------- COMPONENTS
import NewRoyaltyForm from "./NewRoyaltyForm.js";
import StatementForm from "./statements/StatementForm.js";
import NewBalancePaymentForm from "./NewBalancePaymentForm.js";
import LandingPage from "../../components/layout/LandingPage.js";
import Threshold from "./Threshold.js";

import {
  ForeignKeyMultiSelectDropDownInput,
} from "../../inputs/index.js";

import {
  Pane,
  Paragraph,
  AddIcon,
  SideSheet,
  LockIcon,
  Badge,
} from 'evergreen-ui';

function Royalties() {

  // ------- STATES
  const location = useLocation();
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const [royaltys, setRoyaltys] = useState([]);
  const [count, setCount] = useState(0);
  const [pagecount, setPagecount] = useState(0);
  const [currpage, setCurrpage] = useState(1);
  const [params, setParams] = useState({});
  const [sourcePageInfo, setSourcePageInfo] = useState(false)


  // modes
  const [newmode, setNewmode] = useState(false);
  const [statementmode, setStatementmode] = useState(false);
  const [paymentmode, setPaymentmode] = useState(false);


  // PAYMENT properties
  const [creator, setCreator] = useState({});
  const [startDate, setStartDate] = useState({})
  const [endDate, setEndDate] = useState({})
  const [selected, setSelected] = useState([]);

  // ------- HOOKS

  const { loading, error, data, refetch: _refetch } = useQuery(QUERY_ROYALTYS, {
    variables: { publisher: parseInt(currentpublisher.id), page: parseInt(currpage), params: JSON.stringify(params) },
  });

  const refetch = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch]);

  useEffect(() => {
    if (data) {
      setRoyaltys(data.royaltys);
      const csrftoken = getCookie('csrftoken');
      fetch('/count_royaltys/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken
        },
        body: JSON.stringify({ publisher: currentpublisher.id })
      })
        .then(res => res.json())
        .then(data => {
          setCount(parseInt(data.message));
          setPagecount(Math.ceil(data.message / 25));
        })
    }
  }, [data, currentpublisher.id]);

  useEffect(() => {
    if (location.state) {
      let locState = location.state;
      console.log(locState)
        setSourcePageInfo(locState)
    }
  }, [location.state]);

  // ------- FUNCTIONS

  const handleRefetchRoyaltys = useCallback(() => {
    setParams({});
    refetch();
  }, [refetch])

  const handleToggleNewMode = () => {
    setNewmode(!newmode);
  }

  const handleToggleStatementMode = () => {
    setStatementmode(!statementmode);
  }

  const handleTogglePaymentMode = (creator, selected, start, end) => {
    if (!paymentmode) {
      setSelected(selected)
      setStartDate(start)
      setEndDate(end)
      setCreator(creator)
    }
    else {
      setSelected([])
      setStartDate([])
      setEndDate([])
    }
    setPaymentmode(!paymentmode);
  }

  // TODO clear up handling these
  const handleClearSelected = () => {
    setSelected([]);
  }

  const formData = statementmode
    ? (<StatementForm selected={selected} handleClearSelected={handleClearSelected} handleToggleStatementMode={handleToggleStatementMode} handleRefetchRoyaltys={handleRefetchRoyaltys} publisherId={currentpublisher.id} handleTogglePaymentMode={handleTogglePaymentMode} />)
    : paymentmode
      ? (<NewBalancePaymentForm creator={creator} selected={selected} start={startDate} end={endDate} handleRefetchRoyaltys={handleRefetchRoyaltys} handleTogglePaymentMode={handleTogglePaymentMode} publisher={currentpublisher.id} />)
      : "";

  // ------- INPUTS
  const buttons = [
    { title: "NEW ROYALTY", icon: AddIcon, onClick: setNewmode },
    { title: "NEW STATEMENT", icon: AddIcon, onClick: setStatementmode },
  ];

  const cols = [
    { title: "PRODUCT", type: "button", flex: "4", query: "product__title" },
    { title: "ISBN", type: "button", flex: "3", query: "product__isbn", align: "right" },
    { title: "CREATOR", type: "button", flex: "3", query: "creator__display_name" },
    {
      title: "TERMS", type: "tooltip", flex: "5", tooltip: <Pane><Paragraph marginLeft={10} size={300}><b>% of list price:</b> % of title's retail price, no matter how much the publisher earns</Paragraph>
        <Paragraph marginLeft={10} size={300}><b>% of net receipts:</b> % of publisher's income for title, not taking into account expenses</Paragraph>
        <Paragraph marginLeft={10} size={300}><b>% of net profits:</b> % of title's sales minus expenses</Paragraph>
        <Paragraph marginLeft={10} size={300}><b>% of true profits/profit sharing:</b> net profits minus publisher's bottom line percentage</Paragraph></Pane>
    },
    { title: "PAYMENTS", type: "default", flex: "2", align:"right" },
    { title: "BALANCE", type: "default", flex: "2", },
  ]

  const searchInit = {
    product__title: "",
    product__isbn: "",
    creator__display_name: "",
    creator__payment_name: "",
    creator__payment_type__value: ""
  }

  const searchInputs = {
    default: { dropLabel: "", searchq: "creator__display_name" },
    creator__display_name: { dropLabel: "Creator Display Name", searchq: "creator__display_name" },
    product__title: { dropLabel: "Product Title", searchq: "product__title" },
    creator__payment_name: { dropLabel: "Creator Payment Name", searchq: "creator__payment_name" },
    product__isbn: { dropLabel: "Product ISBN", searchq: "product__isbn" },
  }

  const filterInputs = {
    creator__payment_type__value: { comp: ForeignKeyMultiSelectDropDownInput, group: 1, formLabel: "PAYMENT TYPE", values: publisherGlobalData.paymenttypes },
  }

  const csvFields = [
    "adminNotes",
    "amountType",
    "created",
    "creator",
    "id",
    "lastEditor",
    "modified",
    "product",
    "royaltyTerm",
    "thresholdType"
  ];

  const csvConfig = {
    "creator": ["paymentName"],
    "product": ["title", "isbn", "id"],
    "royaltyTerm": ["value"],
    "sets": {},
    "primarySets": {},
    "arrays": {}

  }

  if (error) return `Error! ${error}`;

  return (
    <Pane position="absolute" padding={16} width="100%" height="100%">

      {statementmode || paymentmode ? formData : (
        <Pane>
          <SideSheet isShown={newmode} onCloseComplete={() => setNewmode(false)} className="background" width={700}>
            <NewRoyaltyForm
              handleToggleNewMode={handleToggleNewMode}
              handleRefetchRoyaltys={handleRefetchRoyaltys}
              data={data}
              publisher={currentpublisher.id} />
          </SideSheet>

          <LandingPage
            title="Royalties"
            location={location}
            sourcePageInfo={sourcePageInfo}
            data={royaltys}
            loading={loading}
            buttons={buttons}
            count={count}
            pagecount={pagecount}
            searchInputs={searchInputs}
            filterInputs={filterInputs}
            searchInit={searchInit}
            csvConfig={csvConfig}
            csvFields={csvFields}
            csvType="Royalties"
            cols={cols}
            refetch={refetch}
            setParams={setParams}
            RowComponent={RoyaltyRow}
            currpage={currpage}
            setCurrpage={setCurrpage}
          />
        </Pane>
      )}
    </Pane>
  )
}

function RoyaltyRow({ item, allmode, handleCheck, colWidths, makeIDCell, makeTableCellArray }) {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const [checked, setChecked] = useState(false);

  const handleSetChecked = () => {
    handleCheck(checked, item)
    setChecked(!checked);
  }
  const product = item.product
  const creator = item.creator
  const balance = parseFloat(item.owed) - parseFloat(item.paid)
  const payments = parseFloat(item.paid);

  const currency = publisherGlobalData.currency
    ? publisherGlobalData.currency.symbol
    : "$";

  useEffect(() => {
    setChecked(allmode);
  }, [allmode]);

  const rowCells = [
    {
      content: makeIDCell(checked, handleSetChecked, item.id), disableSelect:true
    },
    {
      content: <Pane>
        {product.frozen && <LockIcon size={12} marginRight={8} color="muted" />}{product.title}
        <br />({product.format ? product.format.description : "No Format"})
      </Pane>
    },
    { isNumber: true, content: product.isbn },
    { content: <Pane>{creator.displayName}<br />{(creator.displayName === creator.paymentName) && !creator.paymentName ? "" : ` (${creator.paymentName})`}</Pane> },
    { content: <Pane paddingY={8}><Threshold royaltylineitemSet={item.royaltylineitemSet} /></Pane> },
    { isNumber: true, content: <Pane>{currency}{payments}</Pane> },
    { content: <Pane><Badge color={balance < 0 ? "green" : balance >= 100 ? "red" : "neutral"}>{balance < 0 ? "-" : ""}{currency}{Math.abs(balance).toFixed(2)}</Badge></Pane> },
  ]

  const cellArray = makeTableCellArray( rowCells, colWidths, item.id)

  return cellArray

}

export default Royalties;