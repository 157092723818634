import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getCookie } from '../../util.js';

import {
  Button,
  Table,
  toaster,
} from 'evergreen-ui';

function GroupInvite(props) {
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const thisinvite = props.publisherinvite;
  
  console.log('============------------===============', props);

  let baseUrl = process.env.REACT_APP_BASE_URL;
  const invitationUrl = baseUrl +
      'accept_invite?invite=' + thisinvite.invite.key + 
      '&group=' + encodeURIComponent(currentpublisher.name) + 
      '&pubid=' + currentpublisher.id.toString();


  const handleCopy = () => {
    toaster.success('Invitation URL copied to clipboard!');
  }

  return (
    <Table.Row className="table-row" value="">
      <Table.TextCell flexShrink={0} flexGrow={3}>{thisinvite.invite.email}</Table.TextCell>
      <Table.TextCell flexShrink={0} flexGrow={2}><em>Invited</em></Table.TextCell>
      <Table.TextCell flexShrink={0} flexGrow={2}>
        <CopyToClipboard text={invitationUrl}
          onCopy={() => handleCopy()}>
          <Button>Copy Invite Url</Button>
        </CopyToClipboard>
      </Table.TextCell>
    </Table.Row>
  )
}

export default GroupInvite;