import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { getCookie } from '../../util.js';

// ------- QUERIES
import QUERY_EXPENSES from "../../graphql/queries/Expenses/QueryExpenses.js";

// ------- COMPONENTS
import LandingPage from "../../components/layout/LandingPage.js";
import NewExpense from "./NewExpense.js";
import CsvUpload from "../../components/layout/CsvUpload.js";

import {
  ForeignKeyMultiSelectDropDownInput,
} from "../../inputs/index.js";

import {
  Pane,
  AddIcon,
  SideSheet,
  DocumentShareIcon,
  Badge
} from 'evergreen-ui';

function Expenses() {

  // ------- STATES
  const location = useLocation();
  const navigate = useNavigate();

  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);

  const [expenses, setExpenses] = useState([]);
  const [count, setCount] = useState(0);
  const [pagecount, setPagecount] = useState(0);
  const [currpage, setCurrpage] = useState(1);
  const [newmode, setNewmode] = useState(false);
  const [csvmode, setCsvmode] = useState(false);
  const [params, setParams] = useState({});
  const [sourcePageInfo, setSourcePageInfo] = useState(false)

  // ------- HOOKS

  const { loading, error, data, refetch: _refetch } = useQuery(QUERY_EXPENSES, {
    variables: { publisher: parseInt(currentpublisher.id), page: parseInt(currpage), params: JSON.stringify(params) },
  });

  const refetch = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch]);

  useEffect(() => {
    if (data) {
      setExpenses(data.expenses);
      const csrftoken = getCookie('csrftoken');
      fetch('/count_expenses/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken
        },
        body: JSON.stringify({ publisher: currentpublisher.id })
      })
        .then(res => res.json())
        .then(data => {
          setCount(parseInt(data.message));
          setPagecount(Math.ceil(data.message / 25));
        })
    }
  }, [currentpublisher.id, data, navigate]);

  useEffect(() => {
    if (location.state) {
      let locState = location.state;
      setSourcePageInfo(locState)
    }
  }, [location.state]);

  // ------- FUNCTIONS
  const handleRefetchExpenses = () => {
    setParams({});
    refetch();
  }

  const handleToggleNewMode = () => {
    setNewmode(!newmode);
  }

  const handleToggleCsvMode = () => {
    setCsvmode(!csvmode);
    refetch();
  }

  // ------- INPUTS

  const buttons = [
    { title: "NEW EXPENSE", icon: AddIcon, onClick: setNewmode },
    { title: "IMPORT EXPENSES", icon: DocumentShareIcon, onClick: setCsvmode },
  ];

  const cols = [
    { title: "DATE", type: "button", flex: "1", query: "expense_date" },
    { title: "AMOUNT", type: "button", flex: "1", query: "amount", align: "right" },
    { title: "PRODUCT", type: "button", flex: "2", query: "product__title" },
    { title: "PRODUCT FORMAT", type: "button", flex: "2", query: "product__format__description" },
    { title: "CATEGORY", type: "button", flex: "1", query: "category__value" },
    { title: "NOTES", type: "button", flex: "3", query: "admin_notes" },
  ];

  const searchInit = {
    product__title: "",
    category__value: "",
    admin_notes: ""
  };

  const searchInputs = {
    default: { searchq: "product__title", dropLabel: "" },
    product__title: { searchq: "product__title", dropLabel: "Product Title" },
    admin_notes: { searchq: "admin_notes", dropLabel: "Notes" },

  };
  const filterInputs = {
    category__value: { comp: ForeignKeyMultiSelectDropDownInput, formLabel: "Category", group: 1, values: publisherGlobalData.expensecategorys },
  };

  const csvFields = [
    "adminNotes",
    "amount",
    "category",
    "expenseDate",
    "id",
    "modified",
    "product",

  ];

  const csvConfig = {
    "category": ["value"],
    "product": ["id", "title", "isbn"],
    "sets": {},
    "primarySets": {},
    "arrays": {}
  };

  // if you make changes to these arrays, be sure to update the lists in views.py as well
  const csvTemplate = [
    [
      "product",
      "isbn",
      "amount",
      "expense_date",
      "category",
      "admin_notes"
    ],
    [
      "The WorkingLit product ID (either ID or ISBN required if no category)",
      "Product ISBN (either ID or ISBN required if no category)",
      "Must be a decimal (only digits and one period allowed)",
      "yyyy-mm-dd",
      "The exact name of the category as it exists in WorkingLit",
      ""
    ],
    [
      "1",
      "",
      "105.99",
      "2021-12-19",
      "Marketing",
      "Mailers for bookstores"
    ]
  ];

  const csvWidths = [
    2,
    3,
    2,
    3,
    4,
    4
  ]

  if (error) return `Error! ${error}`;


  return (
    <Pane position="absolute" padding={16} width="100%" height="100%">
      {
        csvmode ? (
          <CsvUpload handleToggleCsvMode={handleToggleCsvMode} template={csvTemplate} itemType="expense" tableWidth={1000} colWidths={csvWidths} />
        ) : (
          <Pane>
            <SideSheet isShown={newmode} onCloseComplete={() => setNewmode(false)}>
              <NewExpense
                handleToggleNewMode={handleToggleNewMode}
                handleRefetchExpenses={handleRefetchExpenses}
                publisher={currentpublisher.id}
              />
            </SideSheet>
            <LandingPage
              title="Expenses"
              location={location}
              sourcePageInfo={sourcePageInfo}
              data={expenses}
              loading={loading}
              buttons={buttons}
              count={count}
              pagecount={pagecount}
              searchInputs={searchInputs}
              filterInputs={filterInputs}
              searchInit={searchInit}
              csvConfig={csvConfig}
              csvFields={csvFields}
              csvType="Expenses"
              cols={cols}
              refetch={refetch}
              setParams={setParams}
              RowComponent={ExpenseRow}
              currpage={currpage}
              setCurrpage={setCurrpage}
            /></Pane>
        )}
    </Pane>
  )
}

function ExpenseRow({ item, allmode, handleCheck, colWidths, makeIDCell, makeTableCellArray }) {
  const [checked, setChecked] = useState(false);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);

  const handleSetChecked = () => {
    handleCheck(checked, item)
    setChecked(!checked);
  }
  const product = item.product
  const currency = publisherGlobalData.currency
    ? publisherGlobalData.currency.symbol
    : "$";

  const rowCells = [
    {
      content: makeIDCell(checked, handleSetChecked, item.id), disableSelect: true
    },
    { content: item.expenseDate },
    { isNumber: true, content: <Pane>{currency}{item.amount.toFixed(2)}</Pane> },
    { content: product && `${product.title}` },
    { content: !product ? "(No Product)" : product.format ? <Badge color="neutral" marginBottom={8}> {product.format.description}</Badge> : "(No Format)" },
    { content: item.category && item.category.value },
    { content: item.adminNotes },
  ]

  useEffect(() => {
    setChecked(allmode);
  }, [allmode]);
  const cellArray = makeTableCellArray(rowCells, colWidths, item.id)

  return cellArray
}


export default Expenses;
