import React from 'react';
import { Pane } from 'evergreen-ui';

function LoomEmbed({ url }) {
  // Extract the video ID from the Loom URL
  const videoId = url.split('/').pop().split('?')[0];
  
  return (
    <Pane height={400} marginY={16}>
      <iframe
        src={`https://www.loom.com/embed/${videoId}`}
        frameBorder="0"
        allowFullScreen
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          borderRadius: '4px'
        }}
      />
    </Pane>
  );
}

export default LoomEmbed;
