import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { setpublisherGlobalData } from '../../redux/currentpublisher/publisherGlobalDataSlice.js';
import { makeInputs, makeDateString } from '../../util.js';
import { baseRoyaltyPaymentObject } from './BaseRoyaltyPaymentObject.js';
import { SelectRoyalty, SelectCreator } from "../../inputs/index.js";
import QUERY_ROYALTYS from "../../graphql/queries/Royaltys/QueryRoyaltys.js";

import CREATE_ROYALTY_PAYMENT_MUTATION from "../../graphql/mutations/Royaltys/CreateRoyaltyPayment.js";
import CREATE_PAYMENT_TYPE_MUTATION from "../../graphql/mutations/PublisherData/CreatePaymentType.js";

import {
  Pane,
  Paragraph,
  Button,
  Card,
  Spinner
} from 'evergreen-ui';

import {
  ForeignKeyDropDownInput,
  TextInputFieldInput,
  DateFieldInput,
} from "../../inputs/index.js";

function NewRoyaltyPaymentForm(props) {
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const dispatch = useDispatch();

  const [staged, setStaged] = useState(baseRoyaltyPaymentObject);
  const [canSave, setCanSave] = useState(false);
  const [params, setParams] = useState({ 'creator__id': 0 });
  const [royaltys, setRoyaltys] = useState([]);



  const [createRoyaltyPayment, { royaltydata }] = useMutation(CREATE_ROYALTY_PAYMENT_MUTATION);
  const [createPayment, { paymentdata }] = useMutation(CREATE_PAYMENT_TYPE_MUTATION);

  const { loading, error, data } = useQuery(QUERY_ROYALTYS, {
    variables: {
      publisher: parseInt(currentpublisher.id),
      params: JSON.stringify(params),
    }
  });

  useEffect(() => {
    if (data) {
      setRoyaltys(data.royaltys.filter(royalty => !royalty.product.frozen));
    }
  }, [data]);

  const handleAddNewPaymentType = async (val) => {
    const paymenttypes = [].concat(publisherGlobalData.paymenttypes);
    const results = await createPayment(
      {
        variables: {
          publisherId: parseInt(currentpublisher.id),
          paymentType: val,
        }
      });

    const newType = { id: results.data.createPaymentType.message, value: val }
    paymenttypes.push(newType);
    dispatch(setpublisherGlobalData({ paymenttypes: paymenttypes }));
    handleUpdateStaged({ key: "method", val: newType });
  }

  const handleClearStaged = () => {
    setStaged(baseRoyaltyPaymentObject);
  }

  const handleCreateRoyaltyPayment = async () => {
    let payment = {};
    if (staged.method) {
      payment = {
        method: { id: staged.method.id },
        sentDate: makeDateString(staged.sentDate),
        amount: staged.amount
      };
    } else {
      payment = {
        sentDate: makeDateString(staged.sentDate),
        amount: staged.amount
      };
    }
    const results = await createRoyaltyPayment(
      {
        variables: {
          royaltyId: staged.royalty.id,
          payment: JSON.stringify(payment),
        }
      });
    handleClearStaged();
    props.handleToggleNewMode();
    if (!props.fromStatement) {
      props.handleRefetchPayments();
    }
  }

  const handleAttachRoyalty = royaltyString => {
    let royalty = JSON.parse(royaltyString)
    handleUpdateStaged({
      "key": "royalty",
      "val": royalty
    })
  }

  const handleUpdateStaged = obj => {
    let newobj = { ...staged, [obj.key]: obj.val }
    setStaged(newobj);
    checkIfValid(newobj);
  }

  const handleAttachCreator = creatorString => {
    let creator = JSON.parse(creatorString)
    handleUpdateStaged({
      "key": "creator",
      "val": creator
    })
    setParams(oldState => ({ ...oldState, "creator__id": creator.id }));
  }

  const checkIfValid = item => {
    let invalid = false;
    if (!item.royalty || !item.amount || !item.method.id) {
      invalid = true;
    }
    if (invalid) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
  }

  const inputs = {
    method: { width: 250, comp: ForeignKeyDropDownInput, formLabel: "Payment Method", handleChange: handleUpdateStaged, handleAddNew: handleAddNewPaymentType, group: 1, values: publisherGlobalData.paymenttypes, opts:{new:true} },
    amount: { width: 100, comp: TextInputFieldInput, formLabel: "Amount", handleChange: handleUpdateStaged, group: 1, opts: { marginRight: 24 } },
    sentDate: { width: 100, comp: DateFieldInput, formLabel: "Date Sent", handleChange: handleUpdateStaged, group: 2, opts: { marginRight: 24 } },
  }

  const formInputs = makeInputs(staged, inputs, true);

  if (error) return `Error! ${error}`;

  return (
    <Pane backgroundColor="var(--background)" width="100%" height="100%">
      <Pane borderBottom="solid" borderBottomColor="#e6e4e0" borderBottomWidth="1px" paddingX={16} paddingTop={16} paddingBottom={8} width="100%" display="flex" justifyContent="space-between" alignItems="flex-end">
        <Paragraph fontSize={18}> New Royalty Payment </Paragraph>
        <Pane display="flex" flexDirection="row" justifyContent="flex-start">
          <Button className="confirm-button" disabled={!canSave} marginRight={16} onClick={e => {
            handleCreateRoyaltyPayment();
          }}>
            CREATE PAYMENT RECORD
          </Button>
          <Button intent="danger" onClick={e => {
            handleClearStaged();
            props.handleToggleNewMode();
          }}>
            Cancel
          </Button>
        </Pane>

      </Pane>
      <Pane padding={16} >
        <Card backgroundColor="white" paddingX={16} paddingTop={16}elevation={1}>
        <Pane marginBottom={32} display="flex" >
          <Pane marginRight={32}>
            <Paragraph color="#667b7f" size={300}>Creator </Paragraph>
            <SelectCreator handleNewCreator={handleAttachCreator} parent="royalty payments" />
          </Pane>

          <Pane>
            <Pane display="flex" alignItems="center">
            <Paragraph color="#667b7f" size={300} marginRight={8}> Royalty </Paragraph> 
            {loading && <Spinner size={14} />}
            </Pane>
            
            <SelectRoyalty creator={staged.creator} handleNewRoyalty={handleAttachRoyalty} parent="royalty payments" royaltys={royaltys} />
          </Pane>
        </Pane>

        <Pane display="flex">
          {formInputs.group2}
          {formInputs.group1}
        </Pane>

        </Card>
        

      </Pane>

    </Pane>
  )
}

export default NewRoyaltyPaymentForm;