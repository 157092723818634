import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/layout/Breadcrumbs.js";
import SaveButtons from "../../components/layout/SaveButtons.js";
import { baseOrderObject } from './BaseOrderObject.js';
import OrderDetails from "./OrderDetails.js";
import ProductBox from "./ProductBox.js";
import CREATE_ORDER_PRODUCT_MUTATION from "../../graphql/mutations/Orders/CreateOrderProduct.js";
import DELETE_ORDER_PRODUCT_MUTATION from "../../graphql/mutations/Orders/DeleteOrderProduct.js";
import UPDATE_ORDER_MUTATION from "../../graphql/mutations/Orders/UpdateOrder.js";
import DELETE_ORDER_MUTATION from "../../graphql/mutations/Orders/DeleteOrder.js";
import UPDATE_ORDER_PRODUCT_MUTATION from "../../graphql/mutations/Orders/UpdateOrderProduct.js";
import QUERY_ORDER from "../../graphql/queries/Orders/QueryOrder.js";
import { getActualAmountArr, makeDateString, makeInputs } from '../../util.js';
import { CurrencyInput, DateFieldInput, TextInputFieldInput } from "../../inputs/index.js";
import { Badge, Card, Checkbox, EmptyState, InfoSignIcon, Pane, Paragraph, toaster } from 'evergreen-ui';
import OrderCustomer from "./OrderCustomer.js";
import OrderPaymentStatus from "./OrderPaymentStatus.js";

function Order(props) {
  const navigate = useNavigate();
  const currentPublisher = useSelector((state) => state.currentpublisher.value);
  const [order, setOrder] = useState(baseOrderObject);
  const [changed, setChanged] = useState([]);
  const [staged, setStaged] = useState(baseOrderObject);
  const [stagedProducts, setStagedProducts] = useState([]);
  const [canSave, setCanSave] = useState(true);
  const [canDelete, setCanDelete] = useState(false);
  const [newProducts, setNewProducts] = useState([]);
  const [updatedProducts, setUpdatedProducts] = useState([]);
  const [deletedProducts, setDeletedProducts] = useState([]);
  const [editing, setEditing] = useState(false);
  const { orderId } = useParams();

  const handleUpdateStaged = obj => {
    // Filter out any existing change with the same key
    const newArr = changed.filter(item => item.key !== obj.key);
    
    // Add the new change, ensuring the value is never null
    newArr.push({
      key: obj.key,
      val: obj.val === null ? "" : obj.val
    });

    if (obj.key === "customer") {
      const customerFields = ['orderEmail', 'orderAddress', 'orderPhone'];
      customerFields.forEach(field => {
        newArr.push({ key: field, val: '' });
      });
    }

    setChanged(newArr);
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }));

    if (obj.key === "customer") {
      setStaged(oldState => ({
        ...oldState,
        orderAddress: { id: "", line1: "", line2: "", city: "", stateOrRegion: "", country: "", zipcode: "", primary: "", addressType: "" },
        orderEmail: { id: "", valueid: "", primaryid: "" },
        orderPhone: { id: "", value: "", primary: "" },
        [obj.key]: obj.val
      }));
    }

    if (obj.key === 'shipped' && obj.val && !staged.shipDate) {
      const today = makeDateString(new Date());
      setChanged([{ key: 'shipDate', val: today }, ...newArr]);
    }

    if (obj.key === 'paid' && obj.val && !staged.paidDate) {
      const today = makeDateString(new Date());
      setChanged([{ key: 'paidDate', val: today }, ...newArr]);
    }
  };

  const { loading, error, data, refetch: _refetch } = useQuery(QUERY_ORDER, {
    variables: { publisher: parseInt(currentPublisher.id), id: parseInt(orderId) },
  });

  const [updateOrder] = useMutation(UPDATE_ORDER_MUTATION);
  const [createOrderProduct] = useMutation(CREATE_ORDER_PRODUCT_MUTATION);
  const [updateOrderProduct] = useMutation(UPDATE_ORDER_PRODUCT_MUTATION);
  const [deleteOrderProduct] = useMutation(DELETE_ORDER_PRODUCT_MUTATION);
  const [deleteOrder] = useMutation(DELETE_ORDER_MUTATION);

  const refetch = useCallback(() => { setTimeout(() => _refetch(), 0); }, [_refetch]);

  const handleRefetchOrder = () => {
    refetch();
  };

  const handleNewOrderProduct = (product, params) => {
    params.product = product;
    params.retailPrice = product.retailPrice;
    params.calcDiscount = params.discountPerUnit || 0;
    params.calcPrice = params.retailPrice * (1 - params.calcDiscount / 100);

    setNewProducts(prev => [...prev, params]);
    setStagedProducts(prev => [...prev, params]);
    checkIfValid([...stagedProducts, params]);
  };

  const handleUpdateOrderProduct = (item) => {
    const stagedArr = stagedProducts.filter(stagedItem => stagedItem.tmpid !== item.tmpid);
    stagedArr.push(item);
    setStagedProducts(stagedArr);

    if (item.tmpid) {
      const newArr = newProducts.filter(newItem => newItem.tmpid !== item.tmpid);
      setNewProducts(newArr);
    } else {
      const updatedArr = updatedProducts.filter(updatedItem => updatedItem.id !== item.id);
      updatedArr.push(item);
      setUpdatedProducts(updatedArr);
    }
    checkIfValid(stagedArr);
  };

  const handleDeleteOrderProduct = (item) => {
    const stagedArr = stagedProducts.filter(stagedItem => stagedItem.id !== item.id);
    setStagedProducts(stagedArr);

    if (item.tmpid) {
      const newArr = newProducts.filter(newItem => newItem.tmpid !== item.tmpid);
      setNewProducts(newArr);
    } else {
      const updatedArr = updatedProducts.filter(updatedItem => updatedItem.id !== item.id);
      setUpdatedProducts(updatedArr);
      setDeletedProducts(prev => [...prev, item]);
    }
    checkIfValid(stagedArr);
  };

  const resetForm = () => {
    setStaged(order);
    setStagedProducts(order.orderproductSet);
    setChanged([]);
    setNewProducts([]);
    setUpdatedProducts([]);
    setDeletedProducts([]);
  };

  const handleUpdateOrder = async () => {
    try {
      if (changed.length) {
        const formattedChanges = changed.map(change => ({
          key: change.key,
          val: change.val === null ? "" : change.val
        }));

        const results = await updateOrder({
          variables: {
            id: parseInt(staged.id),
            changed: JSON.stringify(formattedChanges),
          }
        });
        toaster.success(results.data.updateOrder.message);
        setChanged([]);
        setOrder(staged);
      }

      if (newProducts.length) {
        const allNew = await Promise.all(newProducts.map(async (orderprod) => {
          const params = Object.fromEntries(
            ['quantity', 'retailPrice', 'calcDiscount', 'calcPrice', 'discountPerUnit', 'netPrice']
              .filter(key => key in orderprod)
              .map(key => [key, orderprod[key]])
          );

          await createOrderProduct({
            variables: {
              productId: parseInt(orderprod.product.id),
              orderId: parseInt(staged.id),
              orderProduct: JSON.stringify(params),
            }
          });
        }));

        toaster.success(`Added ${allNew.length} product(s) to order`, { duration: 10 });
        setNewProducts([]);
      }

      if (updatedProducts.length) {
        const allUpdated = await Promise.all(updatedProducts.map(async (orderprod) => {
          const params = ['quantity', 'retailPrice', 'calcDiscount', 'calcPrice', 'discountPerUnit', 'netPrice']
            .filter(key => key in orderprod)
            .map(key => ({ key, val: orderprod[key] }));

          await updateOrderProduct({
            variables: {
              id: parseInt(orderprod.id),
              changed: JSON.stringify(params),
            }
          });
        }));

        toaster.success(`Updated ${allUpdated.length} product(s) in order`, { duration: 10 });
        setUpdatedProducts([]);
      }

      if (deletedProducts.length) {
        await Promise.all(deletedProducts.map(async (orderprod) => {
          await deleteOrderProduct({
            variables: {
              id: parseInt(orderprod.id),
            }
          });
        }));
        toaster.success(`Deleted ${deletedProducts.length} product(s) from order`, { duration: 10 });
        setDeletedProducts([]);
      }

      handleRefetchOrder();

    } catch (error) {
      toaster.danger("Error saving order. Try again or contact developer", { duration: 5 });
      console.error(error);
    }
  };

  const handleDeleteOrder = async () => {
    try {
      const results = await deleteOrder({
        variables: {
          id: parseInt(staged.id),
        }
      });
      toaster.success(`Order ${results.data.deleteOrder.message} deleted successfully!`, { duration: 10 });
      navigate('/orders');
    } catch (error) {
      toaster.danger("Error deleting Order. Try again or contact developer", { duration: 5 });
      console.error(error.message);
    }
  };

  const handleChooseInfo = (itemString, itemType) => {
    const item = JSON.parse(itemString);
    handleUpdateStaged({ key: itemType, val: item });
  };

  const checkIfValid = arr => {
    const invalid = arr.some(item => !item.quantity || parseInt(item.quantity) === 0);
    setCanSave(!invalid);
  };

  const inputs = {
    paidDate: { width: 100, comp: DateFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 1, opts: { labelDirection: "row", marginBottom: 0, marginLeft: 4 } },
    shipDate: { width: 100, comp: DateFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 2, opts: { labelDirection: "row", marginBottom: 0, marginLeft: 4 } },
    shippingCost: { width: 100, comp: CurrencyInput, formLabel: "", handleChange: handleUpdateStaged, group: 3, opts: { labelDirection: "row", marginBottom: 0, marginLeft: 4, required: false } },
    poNumber: { width: 100, comp: TextInputFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 4, opts: { labelDirection: "row", marginBottom: 0, marginLeft: 4 } },
  };

  const formInputs = makeInputs(staged, inputs, editing);
  const totalAmountArr = getActualAmountArr(stagedProducts);
  const totalPaidArr = stagedProducts.map(item => item.amount);
  const totalQuantityArr = stagedProducts.map(item => parseInt(item.quantity));

  useEffect(() => {
    if (data && data.order.length) {
      setOrder(data.order[0]);
      setStaged(data.order[0]);
      setStagedProducts(data.order[0].orderproductSet);
      setCanDelete(data.order[0].orderproductSet.length === 0);
    }
  }, [data]);

  if (loading) return null;
  if (error) return `Error! ${error}`;
  if (data.order.length === 0) return (
    <Pane>
      <Pane paddingX={16} paddingTop={16}>
        <Breadcrumbs pageLabel="ORDER" sourceUrl="/orders" />
      </Pane>
      <Pane display="flex" justifyContent="center" marginTop={60}>
        <Card alignItems="center" width="50%" elevation={2}>
          <EmptyState
            background="light"
            title="Order not found"
            orientation="vertical"
            icon={<InfoSignIcon size={12} color="#C1C4D6" />}
            iconBgColor="#EDEFF5" />
        </Card>
      </Pane>
    </Pane>
  );

  return (
    <Pane>
      <Pane id="page-heading" borderBottom="solid" borderBottomColor="#e6e4e0" borderBottomWidth="1px" paddingX={16} paddingTop={16}>
        <Breadcrumbs pageLabel="ORDER" curPage={order.id} sourceUrl="/orders" />
        <Pane marginTop={8} marginBottom={8} size={300} display="flex" justifyContent="space-between">
          <Pane display="flex" alignItems="flex-end">
            <Paragraph marginLeft={4} size={300} fontSize={18}>Order</Paragraph>
            <Paragraph marginLeft={8} size={300}><Badge color="neutral">ID: {order.id}</Badge></Paragraph>
            {editing ?
              <Pane display="flex" alignItems="flex-end">
                <Paragraph marginLeft={8} size={300}>PO: </Paragraph>
                {formInputs.group4}
              </Pane>
              : order.poNumber &&
              <Paragraph marginLeft={8} size={300}><Badge color="neutral">PO: {order.poNumber}</Badge></Paragraph>}
            {!editing && order.orderStatus && (
              <Paragraph marginLeft={8} size={300}>
                <Badge color={order.orderStatus.value === "Ready" ? "green" : "neutral"}>
                  {order.orderStatus.value || "Status Unavailable"}
                </Badge>
              </Paragraph>
            )}
            <Pane display="flex" alignItems="center">
              <Checkbox marginY={0} marginLeft={8} marginRight={4} label="PAID" checked={staged.paid}
                disabled={!editing} onChange={() => handleUpdateStaged({ key: 'paid', val: !staged.paid })} />
              <Pane>{editing ? staged.paid ? <Pane>{formInputs.group1}</Pane> : "" : (staged.paid && <Paragraph size={300}>
                {typeof order.paidDate === "string" ? order.paidDate : order.paidDate ? order.paidDate.toISOString().split('T')[0] : ""}</Paragraph>)}</Pane>
              <Checkbox marginY={0} marginLeft={8} marginRight={4} label="SHIPPED" checked={staged.shipped}
                disabled={!editing} onChange={() => handleUpdateStaged({ key: 'shipped', val: !staged.shipped })} />
              {editing ? staged.shipped ? <Pane>{formInputs.group2}</Pane> : "" : (staged.shipped && <Paragraph size={300}>
                {typeof order.shipDate === "string" ? order.shipDate : order.shipDate ? order.shipDate.toISOString().split('T')[0] : ""}</Paragraph>)}
            </Pane>
          </Pane>
          <Pane>
            <SaveButtons
              itemType="order"
              editing={editing}
              locked={order.locked}
              itemId={order.id}
              setEditing={setEditing}
              handleUpdate={handleUpdateOrder}
              handleDelete={handleDeleteOrder}
              deleteMsg={"Cannot delete order if there are any products in order. Edit order to remove products before deletion"}
              resetForm={resetForm}
              refetch={refetch}
              canSave={canSave}
              canDelete={canDelete}
            />
          </Pane>
        </Pane>
      </Pane>
      <Pane display="flex">
        <OrderCustomer order={order} staged={staged} editing={editing} handleChooseInfo={handleChooseInfo} />
        <OrderDetails
          staged={staged}
          handleUpdateStaged={handleUpdateStaged}
          handleRefetchOrder={handleRefetchOrder}
          editing={editing}
          unitCount={totalQuantityArr.reduce((partial_sum, a) => partial_sum + a, 0)}
        />
        <OrderPaymentStatus
          staged={staged}
          editing={editing}
          stagedProducts={stagedProducts}
          handleRefetchOrder={handleRefetchOrder}
          handleUpdateStaged={handleUpdateStaged}
        />
      </Pane>
      <Pane id="order-products" margin={16}>
        <ProductBox
          orderproductSet={stagedProducts}
          handleNewOrderProduct={handleNewOrderProduct}
          handleUpdateOrderProduct={handleUpdateOrderProduct}
          handleDeleteOrderProduct={handleDeleteOrderProduct}
          editing={editing}
          staged={staged}
          stagedProducts={stagedProducts}
          handleRefetchOrders={handleRefetchOrder}
        />
      </Pane>
    </Pane>
  );
}

export default Order;
