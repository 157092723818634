import React, { useState, useEffect, useCallback } from "react";
import { useQuery, useMutation } from '@apollo/client';
import { useParams, Link, useNavigate } from "react-router-dom";
import { useSelector, } from 'react-redux';

// ------- FUNCTIONS
import { commaSepThou, sortItems } from '../../util.js';
import { baseHelpTemplate } from './baseHelpTemplate.js';

// ------- QUERIES/MUTATIONS
import QUERY_CREATOR from "../../graphql/queries/Creators/QueryCreator.js";
import UPDATE_CREATOR_MUTATION from "../../graphql/mutations/Creators/UpdateCreator.js";
import DELETE_CREATOR_MUTATION from "../../graphql/mutations/Creators/DeleteCreator.js";

// ------- COMPONENTS
import ImageUpload from "../../components/ImageUpload.js";
import CloudImage from "../../components/CloudImage.js";
import Breadcrumbs from "../../components/layout/Breadcrumbs.js";
import { ContactInfo } from "../../inputs/index.js";

import HubspotForm from 'react-hubspot-form'
import LoomEmbed from "../../components/LoomEmbed.js";

import {
  Pane,
  Button,
  Paragraph,
  Heading,
  Badge,
  toaster,
  Tablist,
  Tab,
  EmptyState,
  InfoSignIcon,
  Card,
  CaretDownIcon,
  ArrowRightIcon
} from 'evergreen-ui';

function Help() {
  const userid = useSelector((state) => state.user.value);
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [openFAQs, setOpenFAQs] = useState({});
  const [showForm, setShowForm] = useState(false);
  const faqData = [
    {
      question: "How do I invite someone from my team?",
      answer: "On the left side, click your user icon and choose 'Publisher Settings'. Under 'Collaborators', enter the email address of the person you'd like to invite, and click 'Send Invite'."
    },
    {
      question: "Can I export my data?",
      answer: "Absolutely! Most data in WorkingLit can be exported. Look for a select all checkbox above any table. Above that, you'll see 'Export selected'. Click this to save your data in CSV format (which any spreadsheet app will be able to open)."
    },
    {
      question: "How do I enter an Advance on author royalties? (Video)",
      answer: "https://www.loom.com/share/5f960031d1f740f586d31cf810d413ca?sid=304db811-c6cc-49f6-90df-eaa982fce641",
      isVideo: true
    },
    {
      question: "Processing distributor statements: Entering Sales (Video)",
      answer: "https://www.loom.com/share/d217cb7269a14bab9ac1e18f9ca7b3ae?sid=6075c624-1b65-40f1-83d9-69ed458980ef",
      isVideo: true
    },

    
    {
      question: "Processing distributor statements: Entering Returns (Video)",
      answer: "https://www.loom.com/share/7fb09632804e49ef98928c39e13f2c50?sid=ba99aee4-62dc-4e07-b73b-5d9656bc932a",
      isVideo: true
    }
  ];

  const toggleFAQ = (index) => {
    setOpenFAQs(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  // States

  const history = useNavigate();

  // TODO check what these states do


  return (
    <Card margin={16} border="default" padding={16} background="tint">
      <Paragraph fontWeight="bolder" size={500} marginLeft={4}>Help</Paragraph>

      <Paragraph size={600} padding={4} marginBottom={16}>
        From all of us at WorkingLit, thank you for your patience as we build the best backend platform for publishers.
      </Paragraph>

      <Pane marginTop={24} display="flex" width="50%"  float="left" flexDirection="column"  gap={16}>

            <Heading size={700} marginBottom={16}>Frequently Asked Questions</Heading>
            {faqData.map((faq, index) => (
                <Pane key={index} marginBottom={16}>
                    <Pane
                        display="flex"
                        alignItems="center"
                        cursor="pointer"
                        onClick={() => toggleFAQ(index)}
                    >
                        <Heading size={400} marginRight={8}>{faq.question}</Heading>
                        {openFAQs[index] ? <CaretDownIcon /> : <CaretDownIcon />}

                    </Pane>
                    {openFAQs[index] && (
                        <Pane marginTop={8}>
                            {faq.isVideo ? (
                                <LoomEmbed url={faq.answer} />
                            ) : (
                                <Paragraph>{faq.answer}</Paragraph>
                            )}
                        </Pane>
                    )}
                </Pane>
            ))}
        </Pane>
      <Pane marginTop={24} border="none" display="flex" width="50%" float="left" flexDirection="column" gap={16}>

      {/* <Heading size={700} marginBottom={16}>Videos</Heading> */}
            <Paragraph size={400}>
            <Button
          className="new-button"
          size="medium" 
          marginRight={16}
          onClick={() => window.open('https://docs.google.com/document/d/1kmsL433TO11Tq0NDpUKnrt-xwBCuIdB6wL2v9wEJhfs/edit?tab=t.0', '_blank')}
          marginBottom={16}
          iconAfter={ArrowRightIcon}
        >
          Documentation 
        </Button>


            </Paragraph>
      </Pane>
      <br clear="all" />

      <Pane marginTop={36} paddingTop={16} display="flex" borderTop="1px solid var(--lightgreen)" width="100%" flexDirection="column" gap={16}>
        <Button
          width="10%"
          size="medium" marginRight={16}
          onClick={() => setShowForm(!showForm)}
          marginBottom={16}
        >
          Contact Us
        </Button>


        {showForm && (
          <HubspotForm
            portalId='47511468'
            formId='2400c766-d7d9-44b7-b8ca-91c1822e2a61'
            onSubmit={() => console.log('Submit!')}
            onReady={(form) => console.log('Form ready!')}
            loading={<div>Loading...</div>}
          />
        )}
      </Pane>


            <br clear="all" />
    </Card>
  )
}

export default Help;
