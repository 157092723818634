import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import SignupForm from './Login/SignupForm.js';
import { getCookie } from '../util.js';

import {
  Pane,
  Checkbox,
  Button,
  Heading,
  toaster,
} from 'evergreen-ui';

function AcceptInvite(props) {
  // const [invite, setInvite] = React.useState(props.invite);
  // const [group, setGroup] = React.useState(props.group);
  // const [pubid, setPubid] = React.useState(props.pubid);
  const [emailmode, setEmailmode] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [pw, setPw] = React.useState('');
  const [pwconf, setPwconf] = React.useState('');
  const [checked, setChecked] = React.useState(false);

  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(true);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const { invite, group, pubid } = Object.fromEntries(urlSearchParams.entries());

  console.log("URL Parameters:", { invite, group, pubid });

  let navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      navigate('/dashboard');
      // window.location.replace('http://localhost:3000/dashboard');
    } else {
      setLoading(false);
    }
  }, [navigate]);

  const handleSignupFromInvite = (e, user) => {
    e.preventDefault();

    if (!invite) {
      console.error("No invite key found in URL parameters");
      toaster.danger('Invalid invitation link');
      return;
    }

    console.log(user);

    console.log("Publisher Name:", group);
    console.log("Sending invite key:", invite);
    console.log("Request payload:", JSON.stringify({key: invite}));

    const csrftoken = getCookie('csrftoken');

    fetch('/accept_invitation/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify({key: invite})
    })
      .then(response => {
        console.log("Response status:", response.status);
        return response.text().then(text => {
          console.log("Response body:", text);
          if (response.status === 409) {
            toaster.danger('Invitation has already been accepted.')
            return;
          }
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return text ? JSON.parse(text) : {};
        });
      })
      .then(data => {
        console.log("invite accepted!");
        console.log(data);
        const thistoken = getCookie('csrftoken');
        fetch('/api/v1/auth/register/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': thistoken
          },
          body: JSON.stringify(user)
        })
          .then(res => res.json())
          .then(data => {
            if (data.key) {
              const newtoken = getCookie('csrftoken');
              localStorage.clear();
              localStorage.setItem('token', data.key);
              localStorage.setItem('publisher', pubid);
              localStorage.setItem('publisher_name', group);
              fetch('/publisherusers/', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'X-CSRFToken': newtoken
                },
                body: JSON.stringify({publisher: pubid, token: data.key, role: 2})
              })
                .then(res => res.json())
                .then(data => {
                  console.log(data);
                  localStorage.setItem('userid', data.user);
                  window.location.replace(`/dashboard`);
                })
                  .catch(error => {
                    console.log(error);
                  });
            } else {
              setEmail('');
              setPw('');
              setPwconf('');
              localStorage.clear();
              setErrors(true);
            }
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });

    
  }  

  return (
    <Pane id="acceptInvite" height={120} maxWidth={480} marginLeft="auto" marginRight="auto" marginTop={120}>
      <Heading textAlign="center" marginBottom={16}>You've been invited to join <br />{group}<br />on WorkingLit!</Heading>
      <Pane display="flex" flexDirection="column">
        <Checkbox
          label="I agree to the terms and conditions and privacy policy."
          checked={checked}
          onChange={e => setChecked(e.target.checked)}
        />
        <Button margin={8} disabled={!checked} appearance="primary" onClick={e => setEmailmode(!emailmode)}>
          Signup with Email
        </Button>
        <SignupForm emailmode={emailmode} mode="invite" pubid={pubid} handleSignupFromInvite={handleSignupFromInvite} />
        {/* <SocialLogin logintype="Signup" active={checked} /> */}
      </Pane>
    </Pane>
  )
}

export default AcceptInvite;