import React, { useState, } from "react";

import {
  Pane,
  Paragraph,
  Combobox,
  Button,
  Badge,
  AddIcon,
  Tooltip,
  HelpIcon
} from 'evergreen-ui';

const CreatorBox = props => {
  const [addmode, setAddmode] = useState(false);
  const [stagedCreator, setStagedCreator] = useState(false);
  const [stagedCode, setStagedCode] = useState(false);

  const formatArrForDropdown = (arr, field) => {
    let newArr = arr.map(item => {
      let newObj = Object.assign({}, item)
      newObj["label"] = `${item[field]} (${item.id})`;
      return newObj;
    })
    return newArr;
  }

  const handleAttachNewCreatorToProduct = () => {
    props.handleUpdateCreatorStaged(undefined, stagedCreator, stagedCode);
    setAddmode(false);
    setStagedCode(false);
    setStagedCreator(false);
  }

  const canAddNew = props.editing && props.editMode === "edit";
  const addNewPrompt = props.productcreatorSet.length < 1 && props.editMode === "edit";

  const editPrompt = props.newMode
    ? ""
    : "(There are no creators attached to this product. Edit this record to add one.)";

  
  return (
    <Pane >
      <Paragraph size={300} fontWeight="bold" color="#5e5e5e" marginBottom={8}>CREATORS</Paragraph>

      {props.productcreatorSet && (
        props.productcreatorSet.map(creator => {
          let deletable = props.royaltySet.filter(royalty => royalty.creator.id === creator.creator.id).length === 0
          let button = "";
          let creator_type = (<Badge>{creator.onixContributorCode.description}</Badge>);
          let creator_name = (<Paragraph size={300} marginRight={8}>{creator.creator.displayName}</Paragraph>);
          if (props.editing) {
            button =
              <Pane display="flex" alignItems="center">
                <Button size="small" disabled={!deletable} appearance="primary" intent="danger" onClick={e => props.handleDelete(creator.id)} marginLeft={8} marginTop={8}>Delete</Button>
                
                {!deletable && <Tooltip content={
                  <Paragraph color="white" size={300}>Can't delete creator associated with royalty.</Paragraph>}
                  border="default">
                    <HelpIcon size={14} marginTop={6} color="muted" marginLeft={4} />
                    </Tooltip>}

              </Pane>

          }
          return (
            <Pane key={`creator${creator.id}`} borderBottom="default" paddingBottom={8} marginBottom={8} display="flex" alignItems="center">
              {creator_name}
              {creator_type}
              {button}
            </Pane>
          )
        })
      )}
      {addNewPrompt && (
        <Pane>
          <Paragraph fontStyle="italic" size={300}>
            {editPrompt}
          </Paragraph>
        </Pane>
      )}
      {canAddNew && !addmode && (
        <Button iconBefore={AddIcon} onClick={e => setAddmode(!addmode)}>Add Another Creator</Button>
      )}
      {props.editing && addmode && (
        <Pane marginTop={8} display="flex" alignItems="center">
          <Pane >
            <Paragraph size={300} color="#667b7f">Creator Name</Paragraph>
            <Combobox
              items={formatArrForDropdown(props.creators, "displayName")}
              itemToString={item => (item ? item.label : '')}
              onChange={selected => setStagedCreator(selected)}
              marginBottom={8}
              width={150}
              height={20}
            />
          </Pane>
          <Pane marginX={8}>
            <Paragraph size={300} color="#667b7f">Creator Type</Paragraph>
            <Combobox
              items={formatArrForDropdown(props.onixGlobalData.onixcontributorcodes, "description")}
              itemToString={item => (item ? item.label : '')}
              onChange={selected => setStagedCode(selected)}
              marginBottom={8}
              width={150}
              height={20}
            />
          </Pane>
          <Button size="small" marginTop={8} iconBefore={AddIcon} onClick={e => handleAttachNewCreatorToProduct()}>
            Add</Button>
        </Pane>
      )}
    </Pane>
  );
}

export default CreatorBox;
