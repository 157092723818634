import React, {useEffect} from "react";
import { useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { setpublisherGlobalData } from '../../redux/currentpublisher/publisherGlobalDataSlice.js';
import { makeInputs } from '../../util.js';
import CREATE_EXPENSE_CATEGORY_MUTATION from "../../graphql/mutations/PublisherData/CreateExpenseCategory.js";
import AddProductSimple from "../../inputs/select/SelectProduct.js"
import {
  ForeignKeyDropDownInput,
  TextareaFieldInput,
  DateFieldInput,
  CurrencyInput
} from "../../inputs/index.js";

import {
  Pane,
  Card,
  Button,
  toaster
} from 'evergreen-ui';

function ExpenseForm(props) {
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const dispatch = useDispatch();

  const [createExpenseCategory, categoryresult] = useMutation(CREATE_EXPENSE_CATEGORY_MUTATION);

  const handleAddNewExpenseCategory = async (val) => {
    const types = [].concat(publisherGlobalData.expensecategorys);
    const results = await createExpenseCategory(
      {
        variables: {
          publisherId: parseInt(currentpublisher.id),
          expenseCategory: val,
        }
      });

    const newExpenseCategory = { id: results.data.createExpenseCategory.message, value: val }
    types.push(newExpenseCategory);
    dispatch(setpublisherGlobalData({ expensecategorys: types }));
    props.handleUpdateStaged({ key: "category", val: newExpenseCategory });
  }

  const inputs = {
    category: { 
      width: 250, 
      comp: ForeignKeyDropDownInput, 
      formLabel: "Expense Category", 
      handleChange: props.handleUpdateStaged, 
      handleAddNew: handleAddNewExpenseCategory, 
      group: 2, 
      values: publisherGlobalData.expensecategorys, 
      opts: { marginRight: 32, new: true } 
    },
    expenseDate: { 
      width: 200, 
      comp: DateFieldInput, 
      formLabel: "Date", 
      handleChange: props.handleUpdateStaged, 
      group: 1, 
      opts: { marginRight: 32 } 
    },
    amount: { 
      width: 150, 
      comp: CurrencyInput, 
      formLabel: "Amount", 
      handleChange: props.handleUpdateStaged, 
      group: 1,
      opts: { marginRight: 32, autoFocus: true }
    },
    adminNotes: { 
      width: 400, 
      comp: TextareaFieldInput, 
      formLabel: "Notes", 
      handleChange: props.handleUpdateStaged, 
      group: 3 
    },
  }

  const handleChangeWithUpdate = (key, val) => {
    props.handleUpdateStaged({ key, val });
  };

  // loop over the staged array to make the columns
  const formInputs = makeInputs(props.staged, inputs, props.editing);

  useEffect(() => {
    if (categoryresult.loading) {
      toaster.notify("Creating category...", { duration: 60 });
      console.log(categoryresult)
    }
    if (categoryresult.called && !categoryresult.error) {
      toaster.closeAll();
      toaster.success("Category saved!", { duration: 1 });

    } else if (categoryresult.error){
      toaster.closeAll();
      toaster.notify("Error Creating New Category");
    }

  },[categoryresult])

  return (
    <Card display={props.newMode ? "flex" : "inline-flex"} padding={16} margin={8} backgroundColor="white" elevation={2} >
      <Pane>
        <Pane display="flex" >
          {formInputs.group1}
        </Pane>
        <Pane display="flex">
          {formInputs.group2}
          {props.newMode && (
            <AddProductSimple 
              handleAttachProduct={props.handleAttachProduct} 
              product={props.staged.product} 
              editing={props.editing} 
              parent="expenses"
            />
          )}
        </Pane>
        <Pane display="flex">
          {!props.newMode && (
            <AddProductSimple 
              handleAttachProduct={props.handleAttachProduct} 
              product={props.staged.product} 
              editing={props.editing} 
              parent="expenses"/>
          )}
          {props.newMode && formInputs.group3}
        </Pane>
        {!props.newMode && <Pane marginLeft={32}>{formInputs.group3}</Pane>}
        <Pane display="flex" flexDirection="row" justifyContent="flex-start" >
          {props.newMode ? (
            <Button
              className="confirm-button"
              disabled={props.disableSave}
              marginRight={16}
              onClick={() => {
                if (!props.staged.product?.id && !props.staged.category?.id) {
                  props.handleProductError();
                } else {
                  props.handleCreateExpense();
                }
              }}
            >
              CREATE EXPENSE
            </Button>
          ) : null}
          {!props.newMode && (
            <Button onClick={() => props.handleClearStaged()}>
              RESET
            </Button>
          )}
          {props.newMode && (
            <Button onClick={() => {
              props.handleClearStaged();
              props.handleToggleNewMode();
            }}>
              Cancel
            </Button>
          )}
        </Pane>
      </Pane>
      
    </Card>

  )
}

export default ExpenseForm;