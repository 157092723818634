import React, { useEffect, useState} from "react";
import OrderProduct from "./OrderProduct.js";
import { baseOrderObject } from './BaseOrderObject.js';
import OrderPaymentStatus from "./OrderPaymentStatus.js";
import AddOrderProduct from "./AddOrderProduct.js";

import {
  Pane,
  Paragraph,
  Table,
  Card,
  Pill,
} from 'evergreen-ui';

const ProductBox = props => {
  const [staged, setStaged] = useState(baseOrderObject);
  const [canSave, setCanSave] = useState(false);
  const orderprodSet = props.orderproductSet
  
  const addNewPrompt = !props.orderproductSet || props.orderproductSet.length < 1;
  const [idCount, setIdCount] = useState(1)
  
  // const handleNewOrderProduct = (product, options) => {
  //   options.tmpid = idCount
  //   setIdCount(idCount + 1)
  //   props.handleNewOrderProduct(product, options);
  // }

  // const handleNewOrderProduct = (product, params) => {
  //   params.product = product;
  //   params.retailPrice = product.retailPrice;
  //   params.calcDiscount = 0

  //   if (params.discountPerUnit) { params.calcDiscount = params.discountPerUnit }

  //   if (params.netPrice) { params.calcDiscount = 100 * (params.retailPrice - params.netPrice) / (params.retailPrice) }
  //   params.calcPrice = params.retailPrice * (100 - params.calcDiscount) / 100

  //   let stagedArr = stagedProducts.concat(params)
  //   setStagedProducts(stagedArr)
  //   checkIfValid(stagedArr)
  // }

  const handleUpdateStaged = obj => {
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }))
    if (obj.hasOwnProperty("isValid")) {
      checkIfValid(props.stagedProducts)
    }
    
  }

  const checkIfValid = arr => {
    let invalid = arr.some(item => {
      return (!item.quantity || parseInt(item.quantity) === 0)
    }) || arr.length < 1
    if (invalid) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
  }
  
  return (
    <Pane width="100%" height="auto">
      <Paragraph size={400} fontWeight="bold" color="#5e5e5e" marginBottom={8}>ORDER ITEMS {props.orderproductSet && <Pill marginLeft={8} color="green">{props.orderproductSet.length} </Pill>}</Paragraph>

      <Pane display="flex">
        {orderprodSet && (
          <Table.Body flex={2} elevation={1}>
            <Table.Head height={40} className="table-heading">
              <Table.TextCell flex={props.editing ? 4 : 3} fontWeight="bold">
                <Pane fontWeight="bold">
                  TITLE
                </Pane>
              </Table.TextCell>
              {!props.editing && <Table.TextCell flex="3" fontWeight="bold">
                <Pane fontWeight="bold">
                  FORMAT
                </Pane>
              </Table.TextCell>}
              {!props.editing && <Table.TextCell flex="3" fontWeight="bold">
                <Pane fontWeight="bold">
                  ISBN
                </Pane>
              </Table.TextCell>}
              <Table.TextCell flex="2" textAlign="right" >
                <Pane fontWeight="bold">
                  QUANTITY
                </Pane></Table.TextCell>
              <Table.TextCell flex="6" textAlign="right" >
                <Pane fontWeight="bold">
                  PRICE PER UNIT
                </Pane></Table.TextCell>
              <Table.TextCell flex="2" textAlign="right" ><Pane fontWeight="bold" >
                ITEM TOTAL
              </Pane></Table.TextCell>
              {props.editing && <Table.TextCell flex={3}></Table.TextCell>}
            </Table.Head>
            {addNewPrompt && (
              <Pane>
                <Paragraph marginLeft={8} marginTop={8} fontStyle="italic" size={300}>(There are no products attached to this order. Add one using the form on the right.)</Paragraph>
              </Pane>
            )}
            <Pane maxHeight={250} overflowY="scroll">
              {orderprodSet.map(item => {
                return (
                <OrderProduct 
                key={item.id ? `op${item.id}` : `opTemp${item.tmpid}`} 
                item={item} 
                handleDeleteOrderProduct={props.handleDeleteOrderProduct}
                handleUpdateOrderProduct={props.handleUpdateOrderProduct}
                editing={props.editing} />)
              })}
            </Pane>
          </Table.Body>
        )}
        {props.editing &&
          <Card marginLeft={16} backgroundColor="white" flex={1} >
            {/* <AddOrderProduct handleNewOrderProduct={handleNewOrderProduct} /> */}
            <OrderPaymentStatus
              staged={props.staged}
              handleUpdateStaged={handleUpdateStaged}
              editing={true}
              stagedProducts={props.stagedProducts}
              handleRefetchOrder={props.handleRefetchOrders}
            />
          </Card>
        }
      </Pane>
    </Pane>
  );
}

export default ProductBox;
