// TODO: Clean up var names, Rename
import React from "react";

import {
  Pane,
  Paragraph,
  Text,
} from 'evergreen-ui';

const Threshold = props => {
  const categoryLabels = {
    "units sold": "in the product's lifetime, >",
    "discount": "at a discount less than",
  };

  const sortItemsByDiscount = (arr, category) => {
    arr = arr.filter(item => item.category.id == category)
    let discountList = arr.map(item => item.tierDiscount).filter((v, i, a) => a.indexOf(v) === i);
    let discountTmpids = [];

    discountList.forEach((d) => {
      let temparr = arr.filter(item => item.tierDiscount == d)
      temparr.sort((a, b) => (a.thresholdStart > b.thresholdStart) ? 1 : ((b.thresholdStart > a.thresholdStart) ? -1 : 0))
      // Create a new object instead of modifying the existing one
      discountTmpids = discountTmpids.concat(
        temparr.map((item, index) => ({
          ...item,
          first: index === 0
        }))
      )
    })

    return discountTmpids
  }

  return (
    <Pane>
      {sortItemsByDiscount(props.royaltylineitemSet, 1).map(item => {
        return (
          <Pane lineHeight="12px" key={item.id}>
            <Text size={300} marginLeft={10}>{item.amount}% {item.royaltyTerm && item.royaltyTerm.value}</Text>
            {item.thresholdStart != null && ( 
              <Text size={300} marginLeft={2}>, {item.thresholdStart} - {item.thresholdEnd} units </Text>
            )}
          </Pane>
        )
      })}
      {sortItemsByDiscount(props.royaltylineitemSet, 2).map(item => {
        return (
          <Pane lineHeight="12px" key={item.id}>
            {item.first && (<Paragraph size={300} fontWeight="bold">For discount {'>'}= {item.tierDiscount}%: </Paragraph> )}
            <Text size={300} marginLeft={10}>{item.amount}% {item.royaltyTerm && item.royaltyTerm.value}</Text>
            {item.thresholdStart != null && ( 
              <Text size={300} marginLeft={2}>, {item.thresholdStart} - {item.thresholdEnd} units </Text>
            )}
          </Pane>
        )
      })}
    </Pane>
  );
};

export default Threshold;