import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { makeInputs } from '../util.js';

import {
  TextInputFieldInput,
  DateFieldInput,
  ForeignKeyDropDownInput,
} from "../inputs/index.js";

import {
  Pane,
  Button,
  AddIcon,
  Table,
} from 'evergreen-ui';

function AddPayment(props) {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const basePaymentObject = props.payType === "royalty" ? {
    "sentDate": "", "method": props.method,
    "amount": ""
  } : {
    "sentDate": "", "amount": "", "memo": ""
  }
  const [staged, setStaged] = useState(basePaymentObject);
  const [shown, setShown] = useState(false)

  const handleUpdateStaged = obj => {
    // check for an existing record in changed
    delete obj.val.__typename;
    delete obj.val.label;
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }));
  }

  const handleResetForm = () => {setStaged(basePaymentObject);}

  const handleAddPayment = () => {
    props.handleNewPayment(staged)
    handleResetForm()
    setShown(false)
    if (props.emptyState){
      props.setFirstPayment(false)
    }
  }

  const handleClick = () => {
    if (props.emptyState){
      handleResetForm()
      props.setFirstPayment(false)
    }
    else {
      if (shown) {
        handleResetForm()
      }
      setShown(!shown)
    }
  }

  const inputs = props.payType === "royalty" ? {
    sentDate: { width: 100, comp: DateFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 1 },
    method: { width: 80, comp: ForeignKeyDropDownInput, formLabel: "", handleChange: handleUpdateStaged, group: 2 },
    amount: { width: 120, comp: TextInputFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 3 }
  } : {
    sentDate: { width: 100, comp: DateFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 1 },
    amount: { width: 80, comp: TextInputFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 2 },
    memo: { width: 150, comp: TextInputFieldInput, formLabel: "",  handleChange: handleUpdateStaged, group: 3 },
  } 

  // loop over the staged array to make the columns
  const formInputs = makeInputs(staged, inputs, "true");

  return (
    <Pane>
      {(shown || (props.emptyState && props.firstPayment)) && (
        <><Table.Row>
          <Table.Cell overflow="visible">{formInputs.group1}</Table.Cell>
          <Table.TextCell flex={1}>{formInputs.group2}</Table.TextCell>
          <Table.TextCell flex={2}>{formInputs.group3}</Table.TextCell>
        </Table.Row><Table.Row>
            <Table.TextCell>
              <Button marginBottom={8} iconBefore={AddIcon} intent="success" size="small" onClick={handleAddPayment}>
                ADD
              </Button>
            </Table.TextCell>

          </Table.Row></>
      )}
      {props.emptyState ? (
        props.firstPayment ? (
          <Button margin={8} size="small" intent="danger" onClick={handleClick}>
            Cancel
          </Button>
        ) : null
      ) : (
        <Button margin={8} size="small" intent={shown ? "danger" : "default"} onClick={handleClick}>
          {shown ? "Cancel" : "Add Another Payment"}
        </Button>
      )}
    </Pane>
  );
}

export default AddPayment;