import React, { useState, useCallback, useEffect } from "react";
import { useQuery, useMutation } from '@apollo/client';
import { useSelector, } from 'react-redux';
import { useParams, Link, useHistory } from "react-router-dom";
import { makeDateString, checkValidity } from '../../util.js';
import ProductForm from "./ProductForm.js";
import { baseProductObject } from './BaseProductObject.js';

import CREATE_PRODUCT_MUTATION from "../../graphql/mutations/Products/CreateProduct.js";
import CREATE_PRODUCT_CREATOR_MUTATION from "../../graphql/mutations/Products/CreateProductCreator.js";
import QUERY_PRODUCT from "../../graphql/queries/Products/QueryProduct.js";

import {
  Pane,
  Paragraph,
  Button,
  toaster,
  Tooltip,
  HelpIcon
} from 'evergreen-ui';

function NewProduct(props) {

  const [staged, setStaged] = useState(baseProductObject);
  const [stagedCreators, setStagedCreators] = useState([]);
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const [disableSave, setDisableSave] = useState(true);
  const [changed, setChanged] = useState([]);
  const [currentCreators, setCurrentCreators] = useState([]);
  // const [editing, setEditing] = useState(false);
  const [deletedCreators, setDeletedCreators] = useState([]);
  const [product, setProduct] = useState(baseProductObject);
  const [canDelete, setCanDelete] = useState(false);

  const [createProduct, { productdata }] = useMutation(CREATE_PRODUCT_MUTATION);
  const [createProductCreator, { productcreatordata }] = useMutation(CREATE_PRODUCT_CREATOR_MUTATION);
  const { productId } = useParams();

  const handleClearStaged = () => {
    setStaged(baseProductObject);
  }


  const canSave = staged.title && staged.format.id && staged.retailPrice


  const handleCreateProduct = async () => {
    let isValid = checkValidity([{ name: "format", required: true, fieldType: "object", field: "value" }], staged);
    if (isValid) {
      let newProduct = { ...staged };
      if (staged.publicationDate) {
        const pubDate = staged.publicationDate instanceof Date ? 
          staged.publicationDate : 
          new Date(staged.publicationDate);
        newProduct = { ...newProduct, publicationDate: makeDateString(pubDate) };
      }
      if (staged.shipDate) {
        const shipDate = staged.shipDate instanceof Date ? 
          staged.shipDate : 
          new Date(staged.shipDate);
        newProduct = { ...newProduct, shipDate: makeDateString(shipDate) };
      }

      delete newProduct.productcreatorSet;

      const results = await createProduct(
        {
          variables: {
            publisherId: parseInt(props.publisher),
            product: JSON.stringify(newProduct)
          }
        });

      const productId = results.data.createProduct.message;

      let created = stagedCreators.forEach(creator => {
        createProductCreator(
          {
            variables: {
              product: parseInt(productId),
              creator: parseInt(creator.creator.id),
              onixContributorCode: parseInt(creator.onixContributorCode.id)
            }
          });
      });

      toaster.success("New product created!", { duration: 10 })


      setStagedCreators([]);
      handleClearStaged();
      props.handleToggleNewMode();
      props.handleRefetchProducts();
    } else {
      toaster.danger("Please fix the errors before saving");
    }
  }

  const handleAttachOrUpdateCreator = (id, creator, code) => {
    let newObj = {
      id: id ? id : stagedCreators.length + 1,
      creator: creator,
      onixContributorCode: code
    }
    let newarr = stagedCreators;
    if (id) {
      newarr = newarr.filter(creator => creator.id !== id);
    }
    newarr = [newObj].concat(newarr);
    setStagedCreators(newarr);
  }

  const handleRemoveCreator = creatorId => {
    let newStaged = stagedCreators.filter(creator => creator.id !== creatorId);
    setStagedCreators(newStaged);
    let newDeleted = [creatorId].concat(deletedCreators);
    setDeletedCreators(newDeleted);
  }

  const handleUpdateStaged = obj => {
    // Check for an existing record in changed
    delete obj.val.__typename;
    delete obj.val.label;

    // Ensure changed is an array before filtering
    const newArr = Array.isArray(changed)
        ? changed.filter(item => item.hasOwnProperty("key") && item.key !== obj.key)
        : []; // Fallback to an empty array if changed is not defined

    if (obj.key.includes("Date")) {
        obj.val = makeDateString(obj.val);
    }

    newArr.push(obj);
    setChanged(newArr);
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }));
};

  return (
    <Pane position="absolute" width="calc(100vw - 204px)">
      <Pane id="statement-form" elevation={3} backgroundColor="white" padding={16}>
        <Pane display="flex" flexDirection="column">
          <Pane display="flex" flexDirection="row" justifyContent="space-between" marginY={8}>
            <Paragraph size={500} fontWeight="bolder" >New Product</Paragraph>
          </Pane>
          <Pane display="flex" flexDirection="row" width="calc(100vw - 204px)">
            <Pane>
              {/* <CreatorBox
            editing={true}
            onixGlobalData={onixGlobalData}
            productcreatorSet={stagedCreators}
            handleUpdateCreatorStaged={handleAttachOrUpdateCreator}
            handleDelete={handleRemoveCreator}
            creators={publisherGlobalData.publishercreators}
            productId={undefined}
            editMode={"edit"}
            newMode={true}
          /> */}
            </Pane>
            <ProductForm
              staged={staged}
              handleUpdateStaged={handleUpdateStaged}
              handleUpdateCreatorStaged={handleAttachOrUpdateCreator}
              handleDeleteProductCreator={handleRemoveCreator}
              productcreatorSet={stagedCreators.concat(currentCreators)}
              royaltySet={staged.royaltySet}
              editing={true}
              editMode="new"
            />

          </Pane>
        </Pane>
        <Pane display="flex" flexDirection="row" justifyContent="flex-end">
          <Tooltip content="Please make sure title, format, ISBN, and retail price are entered before saving." ><HelpIcon color="muted" marginX={8} /></Tooltip>
          <Button className="btn-save-cancel-txt-bold" disabled={!canSave} marginRight={16} onClick={e => {
            handleCreateProduct();
          }}>
            Save
          </Button>
          <Button className="btn-save-cancel-txt-bold" onClick={e => {
            handleClearStaged();
            props.handleToggleNewMode();
          }}>
            Cancel
          </Button>
          </Pane>
      </Pane>
    </Pane>
  )
}

export default NewProduct;
