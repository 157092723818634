import React from "react";
import { useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { setpublisherGlobalData } from '../../redux/currentpublisher/publisherGlobalDataSlice.js';
import { makeInputs } from '../../util.js';
import CREATE_SHIPPINGTYPE_MUTATION from "../../graphql/mutations/PublisherData/CreateShippingType.js";
import CREATE_PAYMENTTERM_MUTATION from "../../graphql/mutations/PublisherData/CreatePaymentTerm.js";
import CREATE_ORDERSTATUS_MUTATION from "../../graphql/mutations/PublisherData/CreateOrderStatus.js";
import CREATE_SALESPERSON_MUTATION from "../../graphql/mutations/PublisherData/CreateSalesperson.js";

import { ForeignKeyDropDownInput } from "../../inputs/index.js";

import {
  Badge,
  Button,
  Card,
  Pane,
  Paragraph,
  Popover,
  Table,
  Text,
  TextareaField,
} from 'evergreen-ui';

function OrderDetails({ handleUpdateStaged, staged, editing, unitCount }) {
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const dispatch = useDispatch();

  const [createShippingType, { shipdata }] = useMutation(CREATE_SHIPPINGTYPE_MUTATION);

  const handleAddNewShippingType = async (val) => {
    const types = [].concat(publisherGlobalData.shippingtypes);
    const results = await createShippingType(
      {
        variables: {
          publisherId: parseInt(currentpublisher.id),
          shippingType: val,
        }
      });

    const newShipType = { id: results.data.createShippingType.message, value: val };
    types.push(newShipType);
    dispatch(setpublisherGlobalData({ shippingtypes: types }));
    handleUpdateStaged({ key: "shippingType", val: newShipType });
  }

  const [createPaymentTerm, { paydata }] = useMutation(CREATE_PAYMENTTERM_MUTATION);

  const handleAddNewPaymentTerm = async (val) => {
    const terms = [].concat(publisherGlobalData.paymentterms);
    const results = await createPaymentTerm(
      {
        variables: {
          publisherId: parseInt(currentpublisher.id),
          paymentTerm: val,
        }
      });

    const newPayTerm = { id: results.data.createPaymentTerm.message, value: val };
    terms.push(newPayTerm);
    dispatch(setpublisherGlobalData({ paymentterms: terms }));
    handleUpdateStaged({ key: "paymentTerm", val: newPayTerm });
  }

  const [createOrderStatus, { statusdata }] = useMutation(CREATE_ORDERSTATUS_MUTATION);

  const handleAddNewOrderStatus = async (val) => {
    const statuses = [].concat(publisherGlobalData.orderstatuss);
    const results = await createOrderStatus(
      {
        variables: {
          publisherId: parseInt(currentpublisher.id),
          orderStatus: val,
        }
      });

    const newStatus = { id: results.data.createPaymentType.message, value: val };
    statuses.push(newStatus);
    dispatch(setpublisherGlobalData({ orderstatuss: statuses }));
    handleUpdateStaged({ key: "orderStatus", val: newStatus });
  }

  const [createSalesperson, { persondata }] = useMutation(CREATE_SALESPERSON_MUTATION);

  const handleAddNewSalesperson = async (val) => {
    const persons = [].concat(publisherGlobalData.salespersons);
    const results = await createSalesperson(
      {
        variables: {
          publisherId: parseInt(currentpublisher.id),
          salesperson: val,
        }
      });

    const newPerson = { id: results.data.createSalesperson.message, value: val };
    persons.push(newPerson);
    dispatch(setpublisherGlobalData({ salespersons: persons }));
    handleUpdateStaged({ key: "salesperson", val: newPerson });
  }

  // Memoize just the input configurations
  const inputConfigs = React.useMemo(() => ({
    orderStatus: { 
      width: 225, 
      comp: ForeignKeyDropDownInput, 
      handleChange: handleUpdateStaged, 
      handleAddNew: handleAddNewOrderStatus, 
      group: 1, 
      values: publisherGlobalData.orderstatuss, 
      opts: { labelDirection: "row", marginBottom: 0, marginLeft: 4, new: true, short: true} 
    },
    paymentTerm: { 
      width: 225, 
      comp: ForeignKeyDropDownInput, 
      handleChange: handleUpdateStaged, 
      handleAddNew: handleAddNewPaymentTerm, 
      group: 3, 
      values: publisherGlobalData.paymentterms, 
      opts: { height: 28, short: true, new: true } 
    },
    salesperson: { 
      width: 225, 
      comp: ForeignKeyDropDownInput, 
      handleChange: handleUpdateStaged, 
      handleAddNew: handleAddNewSalesperson, 
      group: 2, 
      values: publisherGlobalData.salespersons, 
      opts: { short: true, new: true } 
    },
    shippingType: { 
      width: 225, 
      comp: ForeignKeyDropDownInput, 
      handleChange: handleUpdateStaged, 
      handleAddNew: handleAddNewShippingType, 
      group: 4, 
      values: publisherGlobalData.shippingtypes, 
      opts: { short: true, new: true } 
    },
  }), [
    publisherGlobalData.orderstatuss,
    publisherGlobalData.paymentterms,
    publisherGlobalData.salespersons,
    publisherGlobalData.shippingtypes,
    handleUpdateStaged,
    handleAddNewOrderStatus,
    handleAddNewPaymentTerm,
    handleAddNewSalesperson,
    handleAddNewShippingType
  ]);

  // Create form inputs outside of useMemo
  const formInputs = makeInputs(staged, inputConfigs, editing);

  return (

    <Card id="order-details" border="default" padding={16} margin={8} flex={4} backgroundColor="white" elevation={1}>
      <Pane display="flex" justifyContent="space-between">
        <Paragraph size={400} fontWeight="bold" color="#5e5e5e" marginBottom={8}>ORDER DETAILS</Paragraph>
        <Popover

          content={({ close }) => (
            <Pane width={200}> {editing ? <Pane>
              <TextareaField label="" value={staged.adminNotes} placeholder="Add note here..." onChange={e => handleUpdateStaged({ key: "adminNotes", val: e.target.value })}></TextareaField>
            </Pane> : staged.adminNotes ?
              <Pane padding={8} >
                <Paragraph size={300}>{staged.adminNotes}</Paragraph>
              </Pane> : <Pane padding={8} justifyContent="center">
                <Text size={300} color="var(--robinegg)"> (No note associated with this order)</Text>
              </Pane>}</Pane>

          )}
        >
          <Button>Order Notes</Button>
        </Popover>
      </Pane>

      <Paragraph size={300} color="grey">Order Status</Paragraph>

      <Pane display="flex" flexDirection="column">
        <Pane>
          <Pane display="flex" alignItems="center" justifyContent="flex-start">
            {editing ? formInputs.group1 :
              <Badge marginBottom={4} color={staged.orderStatus?.value === "Ready" ? "green" : "neutral"}>
                {staged.orderStatus?.value ? staged.orderStatus.value : "Status Unavailable"}
              </Badge>}
          </Pane>

          <Table.Row height="auto" paddingY={8} backgroundColor="#f9faff">
            <Table.TextCell>
              Total Units
            </Table.TextCell>
            <Table.TextCell textAlign="right"> {unitCount && unitCount}
            </Table.TextCell>
          </Table.Row>


          <Table.Row height="auto" paddingY={8} backgroundColor="#F4F5F9" >
            <Table.TextCell flex={5}>
              Salesperson
            </Table.TextCell>
            <Table.TextCell flex={3} textAlign="right">
              {editing ? formInputs.group2 : staged.salesperson ? staged.salesperson.value : "None"}
            </Table.TextCell>
          </Table.Row>
          <Table.Row height="auto" paddingY={8} backgroundColor="#f9faff">
            <Table.TextCell  flex={5}>
              Payment Terms
            </Table.TextCell>
            <Table.TextCell flex={3} textAlign="right">
              {editing ? formInputs.group3 : staged.paymentTerm ? staged.paymentTerm.value : "None"}
            </Table.TextCell>
          </Table.Row>
          <Table.Row height="auto" paddingY={8} backgroundColor="#F4F5F9">
            <Table.TextCell flex={5}>
              Shipping Type
            </Table.TextCell>
            <Table.TextCell flex={3} textAlign="right">
              {editing ? formInputs.group4 : staged.shippingType ? staged.shippingType.value : "None"}
            </Table.TextCell>
          </Table.Row>
        </Pane>
      </Pane>
    </Card>
  )
}

export default OrderDetails;