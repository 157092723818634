import React, { useState } from "react";
import { getActualAmountArr, makeDateString, makeInputs, sumArray } from '../../util.js';
import CREATE_ORDER_PAYMENT_MUTATION from "../../graphql/mutations/Orders/CreateOrderPayment.js";
import UPDATE_ORDER_PAYMENT_MUTATION from "../../graphql/mutations/Orders/UpdateOrderPayment.js";
import DELETE_ORDER_PAYMENT_MUTATION from "../../graphql/mutations/Orders/DeleteOrderPayment.js";
import {
  Pane,
  Paragraph,
  Card,
  Button,
  SideSheet,
  Badge,
  toaster,
  Table
} from 'evergreen-ui';
import { useSelector } from "react-redux";
import PaymentTable from "../../components/PaymentTable.js";
import { useMutation } from "@apollo/client";
import { CurrencyInput } from "../../inputs/TextInputs.js";

function OrderPaymentStatus({ staged = {}, editing, stagedProducts = [], handleRefetchOrder, handleUpdateStaged }) {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const [showPayments, setShowPayments] = useState(false);

  const [createOrderPayment] = useMutation(CREATE_ORDER_PAYMENT_MUTATION);
  const [updateOrderPayment] = useMutation(UPDATE_ORDER_PAYMENT_MUTATION);
  const [deleteOrderPayment] = useMutation(DELETE_ORDER_PAYMENT_MUTATION);

  const currency = publisherGlobalData.currency?.symbol || "$";

  const total_amount_arr = getActualAmountArr(stagedProducts);
  const total_paid_arr = staged?.orderpaymentSet?.map(item => item.amount) || [];

  const totalAmount = sumArray(total_amount_arr) + (staged.shippingCost ? parseFloat(staged.shippingCost) : 0);
  const totalPaid = sumArray(total_paid_arr);
  
  const totalDue = Math.max(0, (totalAmount - totalPaid)).toFixed(2);
  const orderTotal = totalAmount.toFixed(2);
  const orderPaid = totalPaid.toFixed(2);
  
  // Order is considered paid when total paid equals or exceeds total amount
  const isFullyPaid = totalPaid >= totalAmount;

  const handleNewOrderPayment = async (payment) => {
    const sentDate = payment.sentDate ? makeDateString(payment.sentDate) : makeDateString(new Date());
    try {
      const result = await createOrderPayment({
        variables: {
          orderId: parseInt(staged.id),
          payment: JSON.stringify({
            memo: payment.memo,
            sentDate: sentDate,
            amount: payment.amount
          })
        }
      });
      console.log("create order payment =>> ", result)
      toaster.closeAll();
      toaster.success(`Recorded order payment: ${result.data.createOrderPayment.message}`);
      handleRefetchOrder();
    } catch (error) {
      toaster.closeAll();
      toaster.danger("Error creating order payment. Try again or contact developer", { duration: 5 });
      console.error(error.message);
    }
  };

  const handleUpdateOrderPayment = async (itemId, changed) => {
    try {
      const result = await updateOrderPayment({
        variables: {
          id: parseInt(itemId),
          changed: changed
        },
      });
      console.log("update order payment =>> ", result)
      toaster.closeAll();
      toaster.success(`Updated order payment ID: ${result.data.updateOrderPayment.message}`);
      handleRefetchOrder();
    } catch (error) {
      toaster.closeAll();
      toaster.danger("Error saving order payment. Try again or contact developer", { duration: 5 });
      console.error(error.message);
    }
  };

  const handleDeleteOrderPayment = async (itemId) => {
    try {
      const result = await deleteOrderPayment({
        variables: {
          id: parseInt(itemId),
        }
      });
      console.log("delete order payment =>> ", result)
      toaster.closeAll();
      toaster.success(`Deleted order payment ID: ${result.data.deleteOrderPayment.message}`);
      handleRefetchOrder();
    } catch (error) {
      toaster.closeAll();
      toaster.danger("Error deleting order payment. Try again or contact developer", { duration: 5 });
      console.error(error.message);
    }
  };

  const inputs = {
    shippingCost: { 
      width: 100, 
      comp: CurrencyInput, 
      formLabel: "", 
      handleChange: handleUpdateStaged, 
      group: 1,
      opts: { autoFocus: true }
    },
  };

  const formInputs = makeInputs(staged, inputs, editing);

  return (
    <Card id="payment-details" marginLeft={16} marginRight={8} marginTop={8} marginBottom={8} elevation={2} backgroundColor="white" flex={4} padding={16}>
      <Pane display="flex" justifyContent="space-between" flex="4">
        <Paragraph size={400} fontWeight="bold" color="#5e5e5e" marginBottom={8}>PAYMENT SUMMARY</Paragraph>
        <Button onClick={() => setShowPayments(true)}>View Payments</Button>
        <SideSheet isShown={showPayments} onCloseComplete={() => setShowPayments(false)} className="background" width={500}>
          <Pane padding={16}>
            <Paragraph size={500} fontWeight="bold" marginBottom={8}>ORDER PAYMENTS</Paragraph>
            <Pane marginBottom={4} display="flex">
              <Paragraph size={400}>
                <Badge color={isFullyPaid ? "green" : "red"}>
                  {isFullyPaid ? "Paid" : "Not Paid"}
                </Badge>
              </Paragraph>
            </Pane>
            <Table.Row height="auto" paddingY={8} backgroundColor="#f9faff">
              <Table.TextCell>Currently Due</Table.TextCell>
              <Table.TextCell textAlign="right">{currency}{totalDue}</Table.TextCell>
            </Table.Row>
            <Table.Row height="auto" paddingY={8} backgroundColor="#F4F5F9">
              <Table.TextCell>Order Total</Table.TextCell>
              <Table.TextCell textAlign="right">{currency}{orderTotal}</Table.TextCell>
            </Table.Row>
            <Table.Row height="auto" paddingY={8} backgroundColor="#f9faff">
              <Table.TextCell>Shipping Cost (Optional)</Table.TextCell>
              <Table.TextCell textAlign="right">{`${currency}${(staged.shippingCost ? parseFloat(staged.shippingCost).toFixed(2) : "0.00")}`}</Table.TextCell>
            </Table.Row>
            <Table.Row height="auto" paddingY={8} backgroundColor="#F4F5F9">
              <Table.TextCell>Payments Received</Table.TextCell>
              <Table.TextCell textAlign="right">{currency}{orderPaid}</Table.TextCell>
            </Table.Row>
            <PaymentTable
              handleRefetchOrder={handleRefetchOrder}
              payType={"order"}
              staged={staged}
              paid={isFullyPaid}
              paymentSet={staged.orderpaymentSet}
              handleNewPayment={handleNewOrderPayment}
              handleUpdatePayment={handleUpdateOrderPayment}
              handleDeletePayment={handleDeleteOrderPayment}
              loading={updateOrderPayment.loading || deleteOrderPayment.loading}
            />
          </Pane>
        </SideSheet>
      </Pane>

      <Paragraph size={300} color="grey">Payment Status</Paragraph>
      <Pane marginBottom={4} display="flex">
        <Paragraph size={400}>
          <Badge color={isFullyPaid ? "green" : "red"}>
            {isFullyPaid ? "Paid" : "Not Paid"}
          </Badge>
        </Paragraph>
      </Pane>

      <Table.Row height="auto" paddingY={8} backgroundColor="#f9faff">
        <Table.TextCell>Currently Due</Table.TextCell>
        <Table.TextCell textAlign="right">{currency}{totalDue}</Table.TextCell>
      </Table.Row>
      <Table.Row height="auto" paddingY={8} backgroundColor="#F4F5F9">
        <Table.TextCell>Order Total</Table.TextCell>
        <Table.TextCell textAlign="right">{currency}{orderTotal}</Table.TextCell>
      </Table.Row>
      <Table.Row height="auto" paddingY={8} backgroundColor="#f9faff">
        <Table.TextCell>Shipping Cost</Table.TextCell>
        <Table.TextCell textAlign="right">
          {editing ? formInputs.group1 : `${currency}${(staged.shippingCost ? parseFloat(staged.shippingCost).toFixed(2) : "0.00")}`}
        </Table.TextCell>
      </Table.Row>
      <Table.Row height="auto" paddingY={8} backgroundColor="#F4F5F9">
        <Table.TextCell>Payments Received</Table.TextCell>
        <Table.TextCell textAlign="right">{currency}{isFullyPaid ? orderTotal : orderPaid}</Table.TextCell>
      </Table.Row>
    </Card>
  );
}

export default OrderPaymentStatus;
