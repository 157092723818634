import React from 'react'; // Import React to avoid JSX scope errors
import { useNavigate } from "react-router-dom";
import {
  Popover,
  Menu,
  Position,
  CaretDownIcon,
  Button,
} from 'evergreen-ui';

function MobileNav(props) {
  let navigate = useNavigate();

  const handleRoute = route => {
    navigate(route);
  };

  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      content={
        <Menu>
          <Menu.Item onSelect={e => handleRoute("/dashboard")}>Dashboard</Menu.Item>
          <Menu.Divider />
          <Menu.Item onSelect={e => handleRoute("/products")}>Products</Menu.Item>
          <Menu.Divider />
          <Menu.Item onSelect={e => handleRoute("/orders")}>Orders</Menu.Item>
          <Menu.Divider />
          <Menu.Item onSelect={e => handleRoute("/customers")}>Customers</Menu.Item>
          <Menu.Divider />
          <Menu.Item onSelect={e => handleRoute("/creators")}>Creators</Menu.Item>
          <Menu.Divider />
          <Menu.Item onSelect={e => handleRoute("/royalties")}>Royalties</Menu.Item>
          <Menu.Divider />
          <Menu.Item onSelect={e => handleRoute("/expenses")}>Expenses</Menu.Item>
          <Menu.Divider />
          <Menu.Item onSelect={e => handleRoute("/reporting")}>Reporting</Menu.Item>
          <Menu.Divider />
        </Menu>
      }
    >
      <Button borderWidth={0} className="user-menu-button" margin={16} iconBefore={CaretDownIcon}>
        Go To...
      </Button>
    </Popover>
  );
}

export default MobileNav;
