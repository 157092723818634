import React, { useState } from "react";
import { useMutation } from '@apollo/client';

// ------- FUNCTIONS
import { makeDateString, makeInputs } from '../../util.js';

// ------- MUTATIONS
import CREATE_ORDER_MUTATION from "../../graphql/mutations/Orders/CreateOrder.js";
import CREATE_ORDER_PRODUCT_MUTATION from "../../graphql/mutations/Orders/CreateOrderProduct.js";

// ------- COMPONENTS
import { baseOrderObject } from './BaseOrderObject.js';
import OrderCustomer from "./OrderCustomer.js";
import OrderDetails from "./OrderDetails.js";
import OrderPaymentStatus from "./OrderPaymentStatus.js";
import ProductBox from "./ProductBox.js";
import AddOrderProduct from "./AddOrderProduct.js";

import {
  Pane,
  Card,
  Paragraph,
  Button,
  toaster,
  Spinner,
  Checkbox,
  Alert,
} from 'evergreen-ui';

import { DateFieldInput, TextInputFieldInput, CurrencyInput } from "../../inputs/TextInputs.js";

function NewOrder(props) {
  const [staged, setStaged] = useState(baseOrderObject());  
  const [stagedProducts, setStagedProducts] = useState([]);
  const [canSave, setCanSave] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nextTmpId, setNextTmpId] = useState(1); // Add counter for temporary IDs
  
  const [createOrder, { orderdata }] = useMutation(CREATE_ORDER_MUTATION, {
    onError: (error) => {
      toaster.danger("Failed to create order: " + error.message);
      setIsSubmitting(false);
    }
  });
  
  const [createOrderProduct, { createdata }] = useMutation(CREATE_ORDER_PRODUCT_MUTATION, {
    onError: (error) => {
      toaster.danger("Failed to add product to order: " + error.message);
      setIsSubmitting(false);
    }
  });

  const validateOrder = () => {
    const newErrors = {};
    
    // Validate customer
    if (!staged.customer || !staged.customer.id) {
      newErrors.customer = "Customer is required";
    }
    
    // Validate products
    if (!stagedProducts.length) {
      newErrors.products = "At least one product is required";
    }
    
    // Validate quantities
    const invalidProducts = stagedProducts.filter(item => !item.quantity || parseInt(item.quantity) === 0);
    if (invalidProducts.length) {
      newErrors.quantities = "All products must have a valid quantity";
    }
    
    // Validate dates if marked as shipped/paid
    if (staged.shipped && !staged.shipDate) {
      newErrors.shipDate = "Ship date is required when order is marked as shipped";
    }
    
    if (staged.paid && !staged.paidDate) {
      newErrors.paidDate = "Payment date is required when order is marked as paid";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClearStaged = () => {
    setStaged(baseOrderObject());  
    setStagedProducts([]);
    setErrors({});
    setIsSubmitting(false);
  }

  const handleUpdateStaged = obj => {
    // Clear related errors when a field is updated
    if (errors[obj.key]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[obj.key];
        return newErrors;
      });
    }
    
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }))
    if (obj.hasOwnProperty("isValid")) {
      checkIfValid(stagedProducts)
    }
  }

  const handleCreateOrder = async () => {
    if (!validateOrder()) {
      toaster.warning("Please fix the errors before saving");
      return;
    }

    setIsSubmitting(true);
    toaster.notify("Creating order...");

    try {
      let newProduct = { ...staged };
      if (newProduct.hasOwnProperty("orderproductSet")) {
        delete newProduct.orderproductSet;
      }
      if (newProduct.hasOwnProperty("orderpaymentSet")) {
        delete newProduct.orderpaymentSet;
      }
      
      // Handle empty shipping cost
      if (newProduct.shippingCost === "") {
        delete newProduct.shippingCost;
      }
      
      // Handle dates
      if (newProduct.shipDate) {
        newProduct = { ...newProduct, shipDate: makeDateString(newProduct.shipDate) };
      }
      if (newProduct.shipped === true && newProduct.shipDate === '') {
        const today = new Date();
        const todayDate = makeDateString(today);
        newProduct = { ...newProduct, shipDate: todayDate }
      }
      if (newProduct.paidDate) {
        newProduct = { ...newProduct, paidDate: makeDateString(newProduct.paidDate) };
      }
      if (newProduct.paid === true && newProduct.paidDate === '') {
        const today = new Date();
        const todayDate = makeDateString(today);
        newProduct = { ...newProduct, paidDate: todayDate }
      }

      const results = await createOrder({
        variables: {
          publisherId: parseInt(props.publisher),
          order: JSON.stringify(newProduct)
        }
      });

      const orderId = results.data.createOrder.message;
      
      // Add products to order
      if (stagedProducts.length) {
        toaster.notify("Adding products to order...");
        const allNew = await Promise.all(stagedProducts.map(async (orderprod) => {
          const params = Object.fromEntries(
            ['quantity', 'retailPrice', 'calcDiscount', 'calcPrice', 'discountPerUnit', 'netPrice']
              .filter(key => key in orderprod)
              .map(key => [key, orderprod[key]])
          );

          const newprod = await createOrderProduct({
            variables: {
              productId: parseInt(orderprod.product.id),
              orderId: parseInt(orderId),
              orderProduct: JSON.stringify(params),
            }
          });
          return newprod;
        }));

        toaster.closeAll();
        toaster.success(`Order created successfully with ${allNew.length} product(s)`, { duration: 10 });
      }

      handleClearStaged();
      props.handleToggleNewMode();
      props.handleRefetchOrders();
      
    } catch (error) {
      toaster.danger("Failed to create order: " + error.message);
      setIsSubmitting(false);
    }
  }

  const inputs = {
    paidDate: { width: 100, comp: DateFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 1, opts: { labelDirection: "row", marginBottom: 0, marginLeft: 4 } },
    shipDate: { width: 100, comp: DateFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 2, opts: { labelDirection: "row", marginBottom: 0, marginLeft: 4 } },
    shippingCost: { width: 100, comp: CurrencyInput, formLabel: "", handleChange: handleUpdateStaged, group: 3, opts: { labelDirection: "row", marginBottom: 0, marginLeft: 4, required: false } },
    poNumber: { width: 100, comp: TextInputFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 4, opts: { labelDirection: "row", marginBottom: 0, marginLeft: 4 } },

  };

  const formInputs = makeInputs(staged, inputs, true);

  const unitCount = stagedProducts.map(item => parseInt(item.quantity)).reduce((partial_sum, a) => partial_sum + a, 0)

  const handleNewOrderProduct = (product, params) => {
    params.product = product;
    params.retailPrice = product.retailPrice;
    params.calcDiscount = 0;
    params.tmpid = nextTmpId; // Assign a unique temporary ID

    if (params.discountPerUnit) { 
      params.calcDiscount = params.discountPerUnit;
    }

    if (params.netPrice) { 
      params.calcDiscount = 100 * (params.retailPrice - params.netPrice) / (params.retailPrice);
    }
    params.calcPrice = params.retailPrice * (100 - params.calcDiscount) / 100;

    let stagedArr = stagedProducts.concat(params);
    setStagedProducts(stagedArr);
    setNextTmpId(nextTmpId + 1); // Increment the counter
    checkIfValid(stagedArr);
  }

  const handleUpdateOrderProduct = (item) => {
    let stagedArr = stagedProducts.filter(stagedItem => stagedItem.tmpid !== item.tmpid)
    stagedArr.push(item);
    checkIfValid(stagedArr)
    setStagedProducts(stagedArr)
  }

  const handleDeleteOrderProduct = (item) => {
    let newarr = stagedProducts.filter(product => product.tmpid !== item.tmpid);
    setStagedProducts(newarr);
    checkIfValid(newarr)
  }

  const handleChooseInfo = (itemString, itemType) => {
    let item = JSON.parse(itemString)
    handleUpdateStaged({ key: itemType, val: item });
  }


  const checkIfValid = arr => {
    let invalid = arr.some(item => {
      return (!item.quantity || parseInt(item.quantity) === 0)
    }) || arr.length < 1
    if (invalid) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
  }

  return (
    <Card position="absolute" display="flex" flexDirection="column" width="calc(100vw - 204px)" elevation={3} padding={16} backgroundColor="white" >
      {Object.keys(errors).length > 0 && (
        <Alert
          intent="warning"
          title="Please fix the following errors:"
          marginBottom={16}
        >
          <ul style={{ margin: 0, paddingLeft: 20 }}>
            {Object.entries(errors).map(([key, value]) => (
              <li key={key}>{value}</li>
            ))}
          </ul>
        </Alert>
      )}
      <Pane id="heading-buttons" display="flex" justifyContent="space-between">
        <Pane display="flex" alignItems="flex-end">
          <Paragraph marginX={16} size={300} fontSize={18}>New Order</Paragraph>
          <Pane display="flex" alignItems="flex-end">
            <Paragraph marginLeft={8} size={300}>PO: </Paragraph>
            {formInputs.group4}
          </Pane>
          <Pane display="flex" alignItems="center">
            <Checkbox marginY={0} marginLeft={8} marginRight={4} label="PAID" checked={staged.paid}
              onChange={e => handleUpdateStaged({ key: 'paid', val: !staged.paid })} />
            <Pane>{formInputs.group1}</Pane>
            <Checkbox marginY={0} marginLeft={8} marginRight={4} label="SHIPPED" checked={staged.shipped}
              onChange={e => handleUpdateStaged({ key: 'shipped', val: !staged.shipped })} />
            <Pane>{formInputs.group2}</Pane>
          </Pane>
        </Pane>
      </Pane>
      <Pane id="form-top" marginTop={8} display="flex" justifyContent="space-between">
        <OrderCustomer newMode order={staged} staged={staged} editing={true} handleChooseInfo={handleChooseInfo} />
        <Card border="default" padding={16} margin={8} flex={4} backgroundColor="white" elevation={1}>
          <AddOrderProduct elevation={2} handleNewOrderProduct={handleNewOrderProduct} />
        </Card>
        {/* <OrderPaymentStatus
          staged={staged}
          handleUpdateStaged={handleUpdateStaged}
          editing={true}
          stagedProducts={stagedProducts}

        /> */}
        <OrderDetails handleUpdateStaged={handleUpdateStaged} staged={staged} editing={true} unitCount={unitCount} />
      </Pane>
      <Pane overflow="auto">
        <ProductBox
          editing={true}
          orderId={false}
          orderproductSet={stagedProducts}
          handleNewOrderProduct={handleNewOrderProduct}
          handleUpdateOrderProduct={handleUpdateOrderProduct}
          handleDeleteOrderProduct={handleDeleteOrderProduct}
          staged={staged}
          stagedProducts={stagedProducts}
          handleRefetchOrders={props.handleRefetchOrders}
        />
      </Pane>
      <Pane display="flex" justifyContent="flex-end">
        <Button 
          className="btn-save-cancel-txt-bold btn-save-cancel-txt-pos" 
          disabled={!canSave || isSubmitting} 
          marginRight={16} 
          onClick={handleCreateOrder}
        >
          {isSubmitting ? "Saving..." : "Save"} {isSubmitting && <Spinner marginLeft={8} size={12} />}
        </Button>
        <Button 
          className="btn-save-cancel-txt-bold btn-save-cancel-txt-pos cancel" 
          onClick={() => {
            handleClearStaged();
            props.handleToggleNewMode();
          }}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
      </Pane>
    </Card >
  )
}

export default NewOrder;
