// TODO: Rename, redo according to Elly notes
import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { makeInputs } from '../../util.js';

import DatePicker from "react-date-picker";

import {
  Pane,
  Button,
  CaretDownIcon,
  Paragraph,
  SelectMenu,
  Select,
  SearchInput,
  Card
} from 'evergreen-ui';

import {
  TextInputFieldInput,
  ForeignKeyDropDownInput,
} from "../../inputs/index.js";

function SearchOrders(props) {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const search_init = {
    id: "",
    shipped: "",
    paid: "",
    backordered: "",
    wholesale: false,
    created__lte: "",
    created__gte: "",
    order_email__value: "",
    customer__full_name: "",
    po_number: "",
    customer__company: "",
    payment_term__value: "",
    shipping_type__value: "",
    orderproduct__product__title: "",
    orderproduct__product__isbn: "",
    salesperson__value: "",
    
  }
  const [searchParams, setSearchParams] = useState({})
  const [stagedParams, setStagedParams] = useState(search_init);
  const [startDate, setStartDate] = useState(search_init.created__gte);
  const [endDate, setEndDate] = useState(search_init.created__lte);
  const [paid, setPaid] = useState(search_init.paid)
  const [shipped, setShipped] = useState(search_init.shipped)
  const [backordered, setBackordered] = useState(search_init.backordered)
  const [advancedOption, setAdvancedOption] = useState(false)
  // const searchParams = props.searchParams;
  // const setSearchParams = props.setSearchParams


  const handleChangeSearchParams = obj => {
    let value;
    if (obj.key === 'shipping_type__value' || obj.key === 'payment_term__value') {
      value = obj.val.value;
    } else {
      value = obj.val;
    }

    setStagedParams(oldState => ({ ...oldState, [obj.key]: value }));

    let params = searchParams
    if (obj.val == null || obj.val === "") {
      delete params[obj.key]
    } else {
      params = { ...params, [obj.key]: value };
    }
    setSearchParams({ ...params })
  }


 
  const search_inputs = {
    default: { width: 500, comp: TextInputFieldInput, searchq: "customer__full_name", formLabel: "", dropLabel: "", placeholder: "Search ...", handleChange: handleChangeSearchParams, group: 4, dropdown: "True", className: "drop-search", opts: { marginBottom: 0 } },
    customer__full_name: { width: 500, comp: TextInputFieldInput, searchq: "customer__full_name", formLabel: "", dropLabel: "By Customer Name", placeholder: "Search by custom ...", handleChange: handleChangeSearchParams, group: 4, dropdown: "True", className: "drop-search", opts: { marginBottom: 0 } },
    customer__company: { width: 500, comp: TextInputFieldInput, searchq: "customer__company", formLabel: "", dropLabel: "By Company Name", handleChange: handleChangeSearchParams, group: 4, dropdown: "True", className: "drop-search" },
    order_email__value: { width: 500, comp: TextInputFieldInput, searchq: "order_email__value", formLabel: "", dropLabel: "By Email", placeholder: "Search by em ...", handleChange: handleChangeSearchParams, group: 5, dropdown: "True", className: "drop-search", opts: { marginBottom: 0 } },
    po_number: { width: 500, comp: TextInputFieldInput, searchq: "po_number", formLabel: "", dropLabel: "By PO Number", placeholder: "Search by po ...", handleChange: handleChangeSearchParams, group: 6, dropdown: "True", className: "drop-search", opts: { marginBottom: 0 } },
    
    salesperson__value: { width: 250, comp: TextInputFieldInput, formLabel: "Sales Person", handleChange: handleChangeSearchParams, group: 2 },
    orderproduct__product__title: { width: 250, comp: TextInputFieldInput, formLabel: "Product Title", handleChange: handleChangeSearchParams, group: 2 },
    orderproduct__product__isbn: { width: 250, comp: TextInputFieldInput, formLabel: "Product ISBN", handleChange: handleChangeSearchParams, group: 2 },
    payment_term__value: { width: 150, comp: ForeignKeyDropDownInput, formLabel: "Payment Term", handleChange: handleChangeSearchParams, group: 2, values: publisherGlobalData.paymentterms, opts: { marginBottom: 0 } },
    shipping_type__value: { width: 150, comp: ForeignKeyDropDownInput, formLabel: "Shipping Type", handleChange: handleChangeSearchParams, group: 2, values: publisherGlobalData.shippingtypes, opts: { marginBottom: 0 } },
  }
  const selectOptions = Object.values(search_inputs).filter(option => option.dropdown === "True")

  let searchButtonInit = selectOptions[0].dropLabel;
  let searchKeyInit = selectOptions[0].searchq;
  let searchInputInit = "";

  selectOptions.forEach(option => {
    let field = option.searchq;

    if (search_init[field] !== "") {
      searchKeyInit = field;
      searchInputInit = search_init[field]
      searchButtonInit = search_inputs[field].dropLabel
    }
  }

  )
  const [searchInput, setSearchInput] = useState(searchInputInit);
  const [searchButton, setSearchButton] = useState(searchButtonInit);
  const [searchKey, setSearchKey] = useState(searchKeyInit);


  const formInputs = makeInputs(stagedParams, search_inputs, true);

  return (
    <Card id="search-wrapper" backgroundColor="white" border="default" paddingY={8} paddingX={16} marginBottom={16} width="100%" elevation={3}>
      <Pane display="flex" flexDirection="row" alignItems="flex-end" justifyContent="flex-start">
        {/* <Pane display="flex" alignItems="flex-end"> */}
          <Pane display="flex">
            <SearchInput
              className={"drop-search"}
              placeholder={"Search..."}
              defaultValue={searchInputInit}
              width={300}
              onChange={e => setSearchInput(e.target.value)}
              onKeyPress={e => { if (e.key === 'Enter') {
                let params = searchParams;
                let stagedSearch = stagedParams;
                for (let [key, value] of Object.entries(searchParams)) {
                  stagedSearch = { ...stagedSearch, [key]: value };
                };
                selectOptions.forEach(option => {
                  let field = option.searchq;
                  if ((field !== searchKey && stagedSearch.hasOwnProperty(field)) || searchInput === "") {
                    delete params[field];
                    stagedSearch[field] = "";
                  } else {
                    params[searchKey] = searchInput;
                    stagedSearch[searchKey] = searchInput;
                  }
                }
                )
                props.handleSearch(params)
              }}}
              autoFocus
            />
            <SelectMenu
              hasTitle={false}
              hasFilter={false}
              options={selectOptions.map(
                item => ({
                  key: item.dropLabel,
                  value: item.searchq,
                  label: item.dropLabel
                })
              )}
              selected={searchButton}
              closeOnSelect={true}
              onSelect={(option) => {
                setSearchButton(option.label)
                setSearchKey(option.value)
              }}
            >
              <Button className="search-dropdown" iconAfter={CaretDownIcon}>{searchButton} </Button>
            </SelectMenu>

          </Pane>
          
          <Button className="confirm-button" marginX={8} onClick={e => {
            let params = searchParams;
            let stagedSearch = stagedParams;
            for (let [key, value] of Object.entries(searchParams)) {
              stagedSearch = { ...stagedSearch, [key]: value };
            };
            selectOptions.forEach(option => {
              let field = option.searchq;
              if ((field !== searchKey && stagedSearch.hasOwnProperty(field)) || searchInput === "") {
                delete params[field];
                stagedSearch[field] = "";
              } else {
                params[searchKey] = searchInput;
                stagedSearch[searchKey] = searchInput;
              }
            }
            )
            props.handleSearch(params)
          }}>SEARCH</Button>
          
          {
            advancedOption && <>
              <Pane id="date-search" display="flex" marginLeft={24}>
                  <Pane>
                    <Paragraph color="#667b7f" size={300}>Created After:</Paragraph>
                    <DatePicker
                      calendarIcon={null}
                      onChange={(date) => {
                        setStartDate(date)
                        handleChangeSearchParams({ key: "created__gte", val: date })
                      }}
                      value={startDate}
                      maxDate={endDate}

                    /></Pane>
                  <Pane marginLeft={16}>
                    <Paragraph color="#667b7f" size={300}>Created Before:</Paragraph>
                    <DatePicker
                      calendarIcon={null}
                      marginLeft={16}
                      onChange={(date) => {
                        setEndDate(date)
                        handleChangeSearchParams({ key: "created__lte", val: date })
                      }}
                      value={endDate}
                      minDate={startDate}

                    />
                  </Pane>
              </Pane>
              <Pane marginLeft={24} display="flex">
                <Pane marginRight={16}>
                  <Paragraph color="#667b7f" size={300}>Paid?</Paragraph>
                  <Select flexBasis={0} value={paid}  onChange={event => {
                    setPaid(event.target.value)
                    handleChangeSearchParams({ key: "paid", val: event.target.value })
                  }}>
                    <option value="" >Any</option>
                    <option value={true} >True</option>
                    <option value={false}>False</option>
                  </Select>
                </Pane>
                <Pane marginRight={16}>
                  <Paragraph color="#667b7f" size={300}>Shipped?</Paragraph>
                  <Select flexBasis={0} value={shipped}  onChange={event => {
                    setShipped(event.target.value)
                    handleChangeSearchParams({ key: "shipped", val: event.target.value })
                  }}>
                    <option value="" >Any</option>
                    <option value={true} >True</option>
                    <option value={false}>False</option>
                  </Select>
                </Pane>
                <Pane>
                  <Paragraph color="#667b7f" size={300}>Backordered?</Paragraph>
                  <Select flexBasis={0} value={backordered}  onChange={event => {
                    setBackordered(event.target.value)
                    handleChangeSearchParams({ key: "backordered", val: event.target.value })
                  }}>
                    <option value="" >Any</option>
                    <option value={true} >True</option>
                    <option value={false}>False</option>
                  </Select>
                </Pane>
              </Pane>
            </>
          }
          
        {/* </Pane> */}

        <Pane 
          display="flex" 
          flexDirection="row" 
          justifyContent="flex-end"
          marginLeft={advancedOption == true ? 100 : 20}
          alignItems="flex-end"
          position="right"
        >
          <Button
            onClick={() => {
              if(!advancedOption) {
                setAdvancedOption(true)
              } else {
                setAdvancedOption(false)
              }
            }}
          >
            { advancedOption ? 'Cancel' : 'Advanced' }
          </Button>
          
        </Pane>
      </Pane>
      {
        advancedOption && <>
            <Pane display="flex" flexDirection="row" marginTop={14} justifyContent="space-between">
            {formInputs.group2}
            </Pane>
        </>
      }

    </Card>
  );
}

export default SearchOrders;