import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SignupForm from './SignupForm.js';
// import SocialLogin from '../Components/SocialLogin.js';
import logo from '../../assets/images/logo-orange.png'

import { getCookie } from '../../util.js';

import {
  Card,
  Pane,
  Tablist,
  Tab,
  TextInputField,
  Button,
  Paragraph,
  Heading,
  DrawIcon,
  ShopIcon,
  BoxIcon,
  ChartIcon,
  LabTestIcon,
  CalculatorIcon

} from 'evergreen-ui';

function Login(props) {
  const [email, setEmail] = useState('');
  const [pw, setPw] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabs = ['SIGN UP', 'LOGIN'];
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(true);

  let navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      navigate('/dashboard');
    } else {
      setLoading(false);
    }
  }, []);


  const validateForm = () => {
    let valid = true;
    if (!email) {
      setErrors("Email is required.");
      valid = false;
    } else if (!pw) {
      setErrors("Password is required.");
      valid = false;
    } else {
      setErrors(false);
    }
    return valid;
  };

  const handleLogin = e => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const csrftoken = getCookie('csrftoken');

    const user = {
      email: email,
      password: pw
    };

    fetch('/api/v1/auth/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify(user)
    })
      .then(res => {
        if (!res.ok) {
          return res.json().then(data => {
            // Handle specific error cases
            if (data.non_field_errors) {
              throw new Error(data.non_field_errors[0]);
            } else if (data.email) {
              throw new Error(`Email error: ${data.email[0]}`);
            } else if (data.password) {
              throw new Error(`Password error: ${data.password[0]}`);
            } else {
              throw new Error('Login failed. Please try again.');
            }
          });
        }
        return res.json();
      })
      .then(data => {
        if (data.key) {
          localStorage.clear();
          localStorage.setItem('token', data.key);
          
          // get the publisher
          const csrftoken1 = getCookie('csrftoken');

          return fetch('/fetch_user_publishers/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrftoken1,
              'Authorization': `Token ${data.key}`
            },
            body: JSON.stringify({ token: data.key })
          });
        } else {
          throw new Error('Invalid response from server');
        }
      })
      .then(res => {
        if (!res.ok) {
          throw new Error('Failed to fetch user data');
        }
        return res.json();
      })
      .then(data => {
        if (!data.user || !data.publisher) {
          throw new Error('Invalid user data received');
        }
        localStorage.setItem('userid', data.user.id);
        localStorage.setItem('publisher', data.publisher.id);
        localStorage.setItem('publisher_name', data.publisher.name);
        window.location.replace(`/dashboard`);
      })
      .catch(error => {
        console.error('Login error:', error);
        setEmail('');
        setPw('');
        localStorage.clear();
        setErrors(error.message || "An unexpected error occurred. Please try again.");
      });
  };

  return (
    <Pane backgroundColor="var(--darkgreen)" display="flex" flexDirection="column" alignItems="center" width="100vw" paddingBottom={32} >
      <Pane backgroundColor="var(--darkgreen)" paddingTop={16}>
        <img className="logo-small" src={logo} alt="working-lit-logo" />
      </Pane>

      <Card display="flex" border="muted" borderWidth={3} marginY={16} width="80vw" elevation={4} >
        <Pane width="55%" backgroundColor="var(--lightgrey)" paddingY={16} paddingX={40} borderTopLeftRadius={5} borderBottomLeftRadius={5} display="flex" flexDirection="column" >


          <Heading size={900} marginTop={32}>
            WorkingLit gives book publishers the tools to flourish. Stay as independent as you want to be while you grow your business and reach readers.

          </Heading>

          <Pane marginLeft={32} marginTop={40} >
            <Pane display="flex">
              <DrawIcon marginRight={24} size={20} color="var(--green)" /><Heading color="var(--green)" size={700} marginBottom={32}>  Manage product and author metadata</Heading>


            </Pane>
            <Pane display="flex">
              <BoxIcon marginRight={24} size={20} color="var(--green)" /><Heading color="var(--green)" size={700} marginBottom={32}>  Track sales and expenses</Heading >


            </Pane>
            <Pane display="flex">
              <ShopIcon marginRight={24} size={20} color="var(--green)" /><Heading color="var(--green)" size={700} marginBottom={32}> Manage customer account data</Heading >


            </Pane>
            <Pane display="flex">
              <CalculatorIcon marginRight={24} size={20} color="var(--green)" /><Heading color="var(--green)" size={700} marginBottom={32}>  Calculate royalties and track payments to authors</Heading >


            </Pane>
            <Pane display="flex">
              <LabTestIcon marginRight={24} size={24} color="var(--green)" /><Heading color="var(--green)" size={700} marginBottom={32}>  Understand the health of your business</Heading >
            </Pane>
            <Pane display="flex">
              <ChartIcon marginRight={24} size={20} color="var(--green)" /><Heading color="var(--green)" size={700} marginBottom={32}>  Make decisions that help you grow</Heading >
            </Pane>
            <Pane display="flex">
              <Heading color="var(--green)" size={700} marginBottom={32}>  <a href="https://www.workinglit.com" target="_blank" rel="noreferrer">Learn more about WorkingLit</a></Heading >
            </Pane>

          </Pane>


          {/* <img className="wl-example" src={dashboard} alt="working-lit-dashboard" /> */}

        </Pane>

        <Pane id="login-container" backgroundColor="var(--lightgreen)" width="45%" paddingY={16} paddingX={32} borderTopRightRadius={5} borderBottomRightRadius={5}>
          <Tablist >
            {tabs.map((tab, index) => (
              <Tab
                key={tab.replace(" ", "")}
                id={tab.replace(" ", "")}
                className="login-tab"
                onSelect={() => setSelectedIndex(index)}
                isSelected={index === selectedIndex}
                aria-controls={`panel-${tab.replace(" ", "")}`}
              >
                {tab}
              </Tab>
            ))}
          </Tablist>
          {tabs.map((tab, index) => (
            <Pane
              key={tab.replace(" ", "")}
              id={`panel-${tab.replace(" ", "")}`}
              role="tabpanel"
              aria-labelledby={tab}
              aria-hidden={index !== selectedIndex}
              display={index === selectedIndex ? 'block' : 'none'}
              marginY={0}
            >
              {tab === "SIGN UP" ? (
                <Pane display="flex" flexDirection="column">
                  <SignupForm emailmode={true} mode="signup" pubid={false} />
                </Pane>
              ) : (
                <Pane display="flex" flexDirection="column" paddingY={16}>
                  <Heading marginBottom={8} size={700} color="var(--darkgreen)" marginTop={32}>Log in to your Account</Heading>
                  <Paragraph marginBottom={32} size={300}>Welcome back! If you're having any issues logging in, please email us.</Paragraph>

                  <TextInputField
                    required={false}
                    label="Email Address"
                    onChange={e => setEmail(e.target.value)}
                    onKeyPress={e => { if (e.key === 'Enter') handleLogin(e); }}
                    value={email}
                    type="email"
                    name="login-email"
                  />
                  <TextInputField
                    required={false}
                    label="Password"
                    onChange={e => setPw(e.target.value)}
                    onKeyPress={e => { if (e.key === 'Enter') handleLogin(e); }}
                    value={pw}
                    type="password"
                    name="login-password"
                  />

                    {errors && <Paragraph color="red">{errors}</Paragraph>}
                    <Button className="confirm-button" onClick={handleLogin}>
                    LOG IN
                  </Button>
                  <Paragraph marginTop={16}>
                    <a href="/reset_password" style={{ color: 'var(--darkgreen)' }}>Forgot Password?</a>
                  </Paragraph>
                </Pane>



              )}
            </Pane>
          ))}

        </Pane>

      </Card>
    </Pane>
  )
}

export default Login;
