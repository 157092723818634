import { Button, Pane, TextInputField } from 'evergreen-ui';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import '../assets/styles/index.css'
import { getCookie } from '../util.js';

function Impersonate() {
  const [number, setNumber] = useState([]);

  const handleUserId = (e) => {
    e.preventDefault();
    const csrftoken = getCookie('csrftoken')
    const superuser = localStorage.getItem('userid');

    const user = {
      superuser: superuser,
      number: number,
    };

    fetch('/personate/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify({ user })
    })
    .then(res => res.json())
    .then(data => {
      if (data.token) {
        localStorage.clear();
        localStorage.setItem('token', data.token);
        // get the publisher
        const csrftoken1 = getCookie('csrftoken');

        fetch('/fetch_user_publishers/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken1
          },
          body: JSON.stringify({ token: data.token })
        })
        .then(res => res.json())
        .then(data => {
          console.log(data)
          localStorage.setItem('userid', data.user.id);
          localStorage.setItem('publisher', data.publisher.id);
          localStorage.setItem('publisher_name', data.publisher.name);
          window.location.replace(`/dashboard`);
        });
      }
    });
  }

  return (
    <div className='impersonate-container'>
        <div className='impersonate'>

          <Pane display="flex" flexDirection="column" paddingY={16}>
              <h1>Impersonate</h1>
              <TextInputField
                  required={false}
                  label="User Id Number"
                  onChange={e => setNumber(e.target.value)}
                  onKeyPress={e => { if (e.key === 'Enter') handleUserId(e); }}
                  value={number}
                  type="text"
              />
              <Button className="confirm-button" onClick={handleUserId}>
                  Post
              </Button>
          <br />
          <hr size="1" />
          <p>Note: To impersonate a user, you must:</p>
          <ul>
            <li>Be a superuser.</li>
            <li>create an auth token for this user in the admin panel.</li>
            <li>Have the user's ID number.</li>
            <li><strong>Tread very carefully.</strong> This is a powerful tool used to help with debugging and user-assistance.</li>
          </ul>

          </Pane>
        </div>
    </div>
  )
}

export default Impersonate;
