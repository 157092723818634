import React, { useEffect, useState, useMemo, useCallback } from "react";
import AddressItem from "./AddressItem";
import {
  Pane,
  Heading,
  Button,
  Popover,
  Card,
  TextInputField,
  Checkbox,
  Paragraph,
  Select,
  AddIcon,
} from 'evergreen-ui';

function AddressBox(props) {
  const [idCount, setIdCount] = useState(1)

  const handleNewAddress = address => {
    address.tmpid = idCount
    setIdCount(idCount + 1)
    props.handleUpdateStagedSet(address, "Address");
  }

  const handleDelete = item => {
    props.handleDeleteItem(item, "Address")
  }

  return (
    <Pane >
      <Pane display="flex" alignItems="center" justifyContent="space-between" marginBottom={8}>
        <Heading size={400} fontWeight="bold" color="#5e5e5e" >ADDRESSES {props.itemaddressSet && `(${props.itemaddressSet.length})`}
          {/* <Pill color="green">{props.itemaddressSet && props.itemaddressSet.length}</Pill> */}
        </Heading>

        {props.editing && (
          <Popover
            content={({ close }) => (
              <NewAddressForm
                handleNewAddress={handleNewAddress}
                close={close}
                itemType={props.itemType}
                cancelled={props.cancelled} />
            )}
          >
            <Button size="small" marginLeft={16}>Add New Address</Button>
          </Popover>
        )}
      </Pane>
      <Pane display="flex" flexDirection="column" alignItems="flex-start" height={500} overflowY="scroll" >
        {props.itemaddressSet && (
          [...props.itemaddressSet].sort((a, b) => {
            if (props.editing) {
              if (a.id === b.id) {
                return b.tmpid - a.tmpid
              } else {
                return b.id - a.id
              }
            } else {
              return b.primary - a.primary
            }
          }).map(address => {
            return (
              <Card key={`address${address.id}${address.primary}${address.tmpid}`} border="default" elevation={1} padding={8} paddingRight={16} marginBottom={8} width="100%">
                <AddressItem
                  address={address}
                  editing={props.editing}
                  handleUpdateAddressItem={props.handleUpdateStagedSet}
                  handleDelete={handleDelete}
                  cancelled={props.cancelled}
                  itemType={props.itemType}
                />
              </Card>
            )
          })
        )}

      </Pane>
    </Pane>
  );
}

export default AddressBox;


function NewAddressForm(props) {
  const baseAddressObject = useMemo(() => {
    let address = {
      line1: "",
      line2: "",
      city: "",
      stateOrRegion: "",
      country: "",
      zipcode: ""
    }
    if (props.itemType === "customer") {
      address["addressType"] = "Shipping";
    }
    return address
  }, [props.itemType])

  const [staged, setStaged] = useState(baseAddressObject);

  const handleClearStaged = useCallback(() => {
    setStaged(baseAddressObject);
  }, [baseAddressObject])

  const handleCreateAddress = () => {
    props.handleNewAddress(staged);
    handleClearStaged();
  }

  const handleUpdateStaged = obj => {
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }))
  }

  useEffect(() => {
    if (props.cancelled === true) {
      handleClearStaged();
    }
  }, [props.cancelled, handleClearStaged]);

  return (
    <Pane padding={16}>
      <Pane display="flex" alignItems="flex-end" marginBottom={8}>
        {staged.hasOwnProperty('addressType') && (

          <Pane marginRight={32}>
            <Paragraph size={300} color="#667b7f">Address Type</Paragraph>

            <Select
              value={props.staged?.addressType }
              onChange={e => handleUpdateStaged({ key: 'addressType', val: e.target.value })}
            >
              <option value="Shipping">Shipping</option>
              <option value="Billing">Billing </option>
              <option value="Other">Other</option>
            </Select>

          </Pane>
        )}
        <Checkbox
          marginTop={0}
          marginBottom={8}
          label="Primary Address?"
          checked={staged.primary}
          onChange={e => handleUpdateStaged({ key: "primary", val: !staged.primary })}
        />

      </Pane>


      <Pane display="flex" marginBottom={8}>
        <Pane>
          <Paragraph size={300} color="#667b7f">Line 1</Paragraph>
          <AddressLine label="Line 1" myKey="line1" value={staged.line1} handleUpdateStaged={handleUpdateStaged} />
        </Pane>
        <Pane marginLeft={8}>
          <Paragraph size={300} color="#667b7f">Line 2</Paragraph>
          <AddressLine label="Line 2" myKey="line2" value={staged.line2} handleUpdateStaged={handleUpdateStaged} />
        </Pane>
      </Pane>
      <Pane display="flex" marginBottom={8}>
        <Pane>
          <Paragraph size={300} color="#667b7f">City</Paragraph>
          <AddressLine label="City" myKey="city" value={staged.city} handleUpdateStaged={handleUpdateStaged} />
        </Pane>
        <Pane marginLeft={8}>
          <Paragraph size={300} color="#667b7f" >State/Region</Paragraph>
          <AddressLine label="State or Region" myKey="stateOrRegion" value={staged.stateOrRegion} handleUpdateStaged={handleUpdateStaged} />
        </Pane>
      </Pane>
      <Pane display="flex">
        <Pane>
          <Paragraph size={300} color="#667b7f">Postal Code</Paragraph>
          <AddressLine label="Postal Code" myKey="zipcode" value={staged.zipcode} handleUpdateStaged={handleUpdateStaged} />
        </Pane>
        <Pane marginLeft={8}>
          <Paragraph size={300} color="#667b7f">Country</Paragraph>
          <AddressLine label="Country" myKey="country" value={staged.country} handleUpdateStaged={handleUpdateStaged} />
        </Pane>
      </Pane>



      <Pane marginTop={16}>
        <Button intent="success" size="small" iconBefore={AddIcon} marginRight={8} onClick={e => {
          handleCreateAddress();
          props.close();
        }}>
          ADD
        </Button>
        <Button intent="danger" size="small" onClick={e => {
          handleClearStaged();
          props.close();
        }}>
          Cancel
        </Button></Pane>

    </Pane>
  )
}

const AddressLine = props => {
  return (
    <TextInputField
      label=""
      className="item-form"
      onChange={e => props.handleUpdateStaged({ key: props.myKey, val: e.target.value })}
      value={props.value}
      marginY={0}
    />
  )
}
