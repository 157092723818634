import React, { useState } from 'react';
import { TextInputField, Button, Paragraph, Heading, Pane } from 'evergreen-ui';
import { getCookie } from '../../util.js';
import { useLocation, Navigate } from 'react-router-dom';

function ResetNewPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const email = query.get('email');
  const token = query.get('token');

  if (!email || !token) {
    return <Navigate to="/reset_password" />;
  }

  const handleResetPassword = e => {
    e.preventDefault();

    if(password === confirmPassword) {
      const user = {
        email: email,
        password: password,
        token: token
      };
      const csrftoken = getCookie('csrftoken');
      fetch('/api/v1/auth/password/confirm/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({ user }),
      })
      .then(res => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then(data => {
        if (data.detail) {
          setMessage(data.detail);
          setSuccess(true);
          // Clear any existing auth
          localStorage.clear();
          // Redirect to login after 2 seconds
          setTimeout(() => {
            window.location.replace('/login');
          }, 2000);
        }
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        setMessage('Error resetting password. Please try again.');
      });
    } else {
      setMessage('Passwords do not match.');
    }
  };

  return (
    <Pane backgroundColor="var(--lightgrey)"  display="flex" flexDirection="column" alignItems="center" padding={32}>
      <Heading size={700} marginBottom={16}>
        <a href="/" style={{  color: 'inherit' }}>
          WorkingLit
        </a>
      </Heading>
      <Heading size={500} marginBottom={16}>Reset Password</Heading>
      <Paragraph marginBottom={32}>Enter your new password.</Paragraph>
      <TextInputField
        label="New Password"
        type="password"
        onChange={e => setPassword(e.target.value)}
        value={password}
      />
      <TextInputField
        label="Confirm Password"
        type="password"
        onChange={e => setConfirmPassword(e.target.value)}
        value={confirmPassword}
      />
      <Button onClick={handleResetPassword} type='submit'>Change Password</Button>
      {message && <Paragraph marginTop={16} color={success ? "green" : "red"}>{message}</Paragraph>}
    </Pane>
  );
}

export default ResetNewPassword;
