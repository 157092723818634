import React from "react";
import ReactDOM from 'react-dom'; 
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import store from './store/store.js';
import { Provider } from 'react-redux';
import App from './App';
import Login from "./views/Login/Login.js";
import ResetPassword from "./views/Login/ResetPassword.js";
import ResetNewPassword from "./views/Login/ResetNewPassword.js";
import AcceptInvite from "./views/AcceptInvite.js";
import reportWebVitals from './reportWebVitals';
import './assets/styles/index.css';
import * as Sentry from "@sentry/react";

// Set the base URL for GraphQL
const baseUrl = process.env.NODE_ENV === 'development' 
  ? process.env.REACT_APP_GRAPHQL_URL 
  : `${window.location.protocol}//${window.location.hostname}`;

Sentry.init({
  dsn: "https://b29aa3e8da966b2daeded4d62bd5707a@o4507947661852672.ingest.us.sentry.io/4507980770181120",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/workinglit\.sentry\.io\/projects/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Create Apollo Client
const client = new ApolloClient({
  uri: `${baseUrl}/graphql/`,
  cache: new InMemoryCache(),
});

// Authentication checks
const checkAuthentication = () => localStorage.getItem('token') !== null;
const checkUserid = () => localStorage.getItem('userid') || false;
const checkPublisher = () => localStorage.getItem('publisher') || false;
const checkPublisherName = () => localStorage.getItem('publisher_name') || false;

const isAuthenticated = checkAuthentication();
const user = checkUserid();
const publisher = checkPublisher();
const publisher_name = checkPublisherName();

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught in ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children; 
  }
}

// Render the application
ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/reset_password" element={<ResetPassword />} />
          <Route path="/reset_password/:resetToken" element={<ResetNewPassword />} />
          <Route path="/accept_invite" element={<AcceptInvite />} />
          <Route path="*" element={
            isAuthenticated ? 
              <App 
                isAuthenticated={isAuthenticated} 
                user={user} 
                publisher={publisher} 
                publisherName={publisher_name} 
                baseUrl={baseUrl} 
              /> 
              : <Navigate to="/login" />
          } />       
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);

// Report web vitals
reportWebVitals();
