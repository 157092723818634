import React, { useState } from 'react';
import AddPayment from "./AddPayment.js";
import { useSelector } from 'react-redux';
import { makeDateString, makeInputs, numbersOnly } from '../util.js';

import {
  DateFieldInput,
  ForeignKeyDropDownInput,
  TextInputFieldInput,
} from "../inputs/index.js";

import {
  Pane,
  Table,
  Button,
  EmptyState,
  AddIcon,
  IconButton,
  TickIcon,
  EditIcon,
  TrashIcon,
  CrossIcon,
  Paragraph,
  Badge,
  InfoSignIcon,
} from 'evergreen-ui';

function PaymentTable(props) {
  const addNewPrompt = props.paymentSet?.length < 1;
  const [firstPayment, setFirstPayment] = useState(false)

  // props.paid now correctly reflects if the order is fully paid
  const isPaid = props.paid;

  if(isPaid) {
    console.log("payment table paid =>>> ", "true")
  } else {
    console.log("payment table paid =>>> ", props.staged?.id)
  }

  return (
    <Pane height="auto" >
      {props.paymentSet && (
        <Table marginTop={16} width={props.payType === "royalty" ? 800 : "auto"}>
          <Table.Head height={40} className="table-heading">
            <Table.HeaderCell flex={1} >Date Sent</Table.HeaderCell>
            <Table.HeaderCell flex={1} >Amount</Table.HeaderCell>
            {props.payType === "royalty" ? <Table.HeaderCell flex={1} >Payment Type</Table.HeaderCell>
              : <Table.HeaderCell flex={1} >Memo</Table.HeaderCell>
            }
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Head>
          <Table.Body overflow="visible">
            {props.paymentSet.map(item => {
              return <PaymentRow item={item} payType={props.payType} key={item.id} handleDeletePayment={props.handleDeletePayment} handleUpdatePayment={props.handleUpdatePayment} handleNewOrderPayment={props.handleNewPayment} />
            })}
            {addNewPrompt && !firstPayment && !isPaid && (
              <EmptyState
                background="light"
                title={`No payments recorded for this ${props.payType}`}
                orientation="horizontal"
                icon={<AddIcon color="var(--lightgreen)" />}
                iconBgColor="var(--palegreen)"
                primaryCta={
                  <Button onClick={e => setFirstPayment(true)}>Record Payment</Button>
                }
              />

            )}
            {addNewPrompt && !firstPayment && isPaid && (
              <EmptyState
                background="light"
                title={`This order has already been marked paid.`}
                orientation="horizontal"
                icon={<InfoSignIcon color="var(--lightgreen)" />}
                iconBgColor="var(--palegreen)"
              />

            )}

            {props.staged?.id === '' && (
              <EmptyState
                background="light"
                title={`Please order first. No order history for this.`}
                orientation="horizontal"
                icon={<InfoSignIcon color="var(--lightgreen)" />}
                iconBgColor="var(--palegreen)"
              />

            )}

            {
              props.staged?.id !== '' && (
                <AddPayment payType={props.payType} method={props.method} handleNewPayment={props.handleNewPayment} emptyState={addNewPrompt} firstPayment={firstPayment} setFirstPayment={setFirstPayment} />
              )
            }


          </Table.Body>
        </Table>
      )}
    </Pane>
  );
}

export default PaymentTable;

function PaymentRow(props) {
  console.log(props)
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const initialPaymentObject = props.payType === "royalty" ? {
    "sentDate": props.item.sentDate, "method": props.item.method,
    "amount": props.item.amount,
  } : {
    "sentDate": props.item.sentDate, "memo": props.item.memo,
    "amount": props.item.amount,
  }
  const [staged, setStaged] = useState(initialPaymentObject);
  const [editing, setEditing] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  const handleUpdateStaged = obj => {
    // check for an existing record in changed
    delete obj.val.__typename;
    delete obj.val.label;

    if (obj.key === "sentDate") {
      obj.val = makeDateString(obj.val)
    }

    if (obj.key === "amount") {
      if (!numbersOnly(obj.val)) {
        setDisableSave(true);
      } else {
        setDisableSave(false);
      }
    }
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }));
  }

  const handleUpdate = itemId => {
    const changed = [{ key: "sentDate", val: staged.sentDate }, { key: "amount", val: staged.amount }]
    if (staged.method) {
      changed.push({ key: "method", val: staged.method })
    }
    if (staged.memo) {
      changed.push({ key: "memo", val: staged.memo })
    }
    props.handleUpdatePayment(itemId, JSON.stringify(changed));
    setStaged(initialPaymentObject)
    setEditing(false);
  }

  const handleDelete = itemId => {
    props.handleDeletePayment(itemId);
    setEditing(false);
  }

  const handleCancel = () => {
    setStaged(initialPaymentObject)
    setEditing(false)
  }

  const inputs = props.payType === "royalty" ? {
    sentDate: { width: 100, comp: DateFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 1 },
    amount: { width: 100, comp: TextInputFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 2 },
    method: { width: 140, comp: ForeignKeyDropDownInput, formLabel: "", handleChange: handleUpdateStaged, group: 3, values: publisherGlobalData.paymenttypes, opts: { showLabel: false, short: true } },
  } : {
    sentDate: { width: 100, comp: DateFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 1 },
    amount: { width: 100, comp: TextInputFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 2 },
    memo: { width: 140, comp: TextInputFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 3, },
  }

  const currency = publisherGlobalData.currency
    ? publisherGlobalData.currency.symbol
    : "$";

  let paymentForm = makeInputs(staged, inputs, true);
  let sentDate = editing
    ? paymentForm.group1
    : props.item.sentDate !== undefined
      ? <Paragraph size={300}> {props.item.sentDate}</Paragraph>
      :  <Paragraph size={300} color="grey">(No Date)</Paragraph>
  let amount = editing
    ? paymentForm.group2
    : props.item.amount !== undefined
      ? props.item.amount
      : "No Amount";
  let method = editing
    ? paymentForm.group3
    : (props.item.method && props.item.method?.value !== undefined)
      ? <Badge>{props.item.method.value}</Badge> : <Paragraph size={300} color="grey">(None Selected)</Paragraph>
  let memo = editing
    ? paymentForm.group3
    : (props.item.memo !== undefined)
      ? <Paragraph flexWrap="wrap" size={300}> {props.item.memo}</Paragraph> : <Paragraph size={300} color="grey">(No Memo)</Paragraph>

  return (
    <>
      {
        staged.amount !== '' && !props.paid && (<Table.Row className="table-row" value="">
          <Table.Cell overflow="visible">{sentDate}</Table.Cell>
          <Table.TextCell isNumber><Pane display="flex" alignItems="center"><Pane marginRight={4}>{currency}</Pane>{amount}</Pane></Table.TextCell>
          <Table.TextCell>{props.payType === "royalty" ? method : memo}</Table.TextCell>
          {editing ? (
            <Table.Cell display="flex" paddingX={8}>
              <IconButton icon={TickIcon} marginRight={8} intent="success" disabled={disableSave} onClick={e => handleUpdate(props.item.id)} />
              <IconButton icon={CrossIcon} onClick={e => handleCancel()} />
            </Table.Cell>
          ) : (
            <Table.Cell display="flex" paddingX={8}>
              <IconButton icon={EditIcon} marginRight={8} onClick={e => setEditing(true)} />
              <IconButton icon={TrashIcon} marginRight={8} intent="danger" onClick={e => handleDelete(props.item.id)} />
            </Table.Cell>
          )}
        </Table.Row>)
      }
    </>
  )
}
